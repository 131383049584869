import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import DeliciousProduct from '../Components/Delicious/DeliciousProduct'
import MinimalProduct from '../Components/Minimal/MinimalProduct'
import ServiceProduct from '../Components/Service/ServiceProduct'

export default function GetProduct({ data }) {
  const { store, setStore } = useContext(StoreContext)
  if (store.theme === 'minimal') {
    return <MinimalProduct data={data} />
  }
  if (store.theme === 'delicious') {
    return <DeliciousProduct data={data} />
  }
  if (store.theme === 'service') {
    return <ServiceProduct data={data} />
  }
}
