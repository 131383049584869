import React, { Component } from "react";
import { StoreContext } from "../../Context/StoreContext";
import { getCategoriesAPI } from "../../Utils/GetCategories";

export default class ServiceCategories extends Component {
    static contextType = StoreContext;

    constructor() {
        super()
        this.state = { categories: [] }
    }

    componentDidMount() {
        getCategoriesAPI(this.context.store.id).then(response => { this.setState({ categories: response }); }); //GET CATEGORIES
    }

    render() {
        return (
            <div className="absolute left-0 right-0 z-30 flex flex-row pt-16 pb-4 transition-all duration-700">
                <div className="container flex flex-row gap-6 pt-1">
                    {this.state.categories.map((category, index) => {
                        return (
                            <a key={index} href={`/category/${category.id}`} className={`flex flex-row tracking-tight font-bold`} >
                                <span className="flex text-sm uppercase transition-all duration-700 text-slate-800 mix-blend-exclusion shrink-0 grow">{category.nombrecategoria}</span>
                            </a>
                        )
                    })}
                </div>
            </div>
        );
    }
}