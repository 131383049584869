import React from "react";

export default function DeliciousAbout({ data, textWe }) {
    return (
        <div className="py-12 bg-black">
            <div className="container flex flex-col p-4">
                <div className="flex mb-6 text-2xl text-white">{textWe}</div>
                <div className="flex flex-col gap-6 lg:flex-row">
                    <div className="flex flex-col items-center justify-center w-full gap-6 md:w-2/3">
                        <img className="inline rounded-xl md:hidden" src={data.primeraimagennosotros ? data.primeraimagennosotros : ""} />
                        <div className="flex-grow hidden w-full bg-center bg-cover md:flex rounded-xl h-128"
                            style={{ backgroundImage: `url(${data.primeraimagennosotros ? data.primeraimagennosotros : ""})` }}
                        >
                        </div>
                    </div>
                    <div className="flex flex-col justify-between w-full gap-6 text-white md:w-1/3">
                        <div className="text-white whitespace-pre-line">{data.about && data.about.info}</div>
                        <div>
                            <div className="text-3xl">{data.about && data.about.address}</div>
                            <div className="text-2xl">{data.about && data.about.district}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
