import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import DeliverySearchBar from "../Components/Delicious/DeliciousSearchBar"
import MinimalSearchBar from "../Components/Minimal/MinimalSearchBar"
import ServiceSearchBar from "../Components/Service/ServiceSearchBar"

export default function GetSearchBar(props) {
    const { store, setStore } = useContext(StoreContext)
    if (store.theme === "minimal") { return (<MinimalSearchBar />) }
    if (store.theme === "service") { return (<ServiceSearchBar />) }
    if (store.theme === "delicious") { return (<DeliverySearchBar />) }
}