import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import UIStorefrontCartContents from '../Components/UIStoreforntCartContents'
import UIStorefrontCardTotals from '../Components/UIStorefrontCartTotals'
import GetFooter from '../Hooks/GetFooter'
import { verifyStock } from '../Utils/VerifyStock'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { LanguageContext } from '../Context/LanguageContext'

export default function Carrito(props) {
  let history = useHistory()
  let [loading, setLoading] = useState(false)
  const language = useContext(LanguageContext)
  const textCar = language.text.car

  const verify = async () => {
    setLoading(true)
    const data = await verifyStock()

    if (data.length >= 1) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Hubo un problema...',
        text: `Ya no tenemos el mismo stock que tu carrito  en estos producto ${data.join(
          ', '
        )}
          por favor elimina esos productos
          
          `,
      })
    } else {
      setLoading(false)
      history.push('/checkout')

      // console.log('checkout')
    }
  }

  return (
    <div>
      <div className='container flex flex-col justify-center  px-6 py-6'>
        <div className='mb-4'>
          <div className='   py-2 text-4xl font-semibold tracking-tighter'>
            {/* {textCar.title} */}
          </div>
          <UIStorefrontCartContents />
        </div>
      </div>
    </div>
  )
}
