import React, { useEffect, useState, useContext } from 'react'
import { StoreContext } from '../../Context/StoreContext'
import { getTermAPI } from '../../Utils/GetTerms'
import ReactMarkdown from 'react-markdown'

export default function DeliciousTyC({ data }) {
  const { store, setStore } = useContext(StoreContext)
  const [content, setContent] = useState(null)

  useEffect(() => {
    getTermAPI(store.tycstore).then((res) => {
      setContent(res)
    })
  }, [])

  return (
    <div className='container flex flex-col gap-8 mb-8  min-h-screen'>
      <div className='flex-grow'>
        <div className='text-4xl font-bold'> Términos y Condiciones </div>
        <ReactMarkdown>{content?.texto}</ReactMarkdown>
      </div>
    </div>
  )
}
