import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import MinimalAbout from "../Components/Minimal/MinimalAbout"
import DeliciousAboutUs from "../Components/Delicious/DeliciousAboutUs"
import ServiceAboutUs from "../Components/Service/ServiceAboutUs"

export default function GetAboutUs({ data, textWe }) {
    const { store, setStore } = useContext(StoreContext)
    if (store.theme === "minimal") { return (<MinimalAbout data={data} textWe={textWe}/>) }
    if (store.theme === "delicious") { return (<DeliciousAboutUs data={data} textWe={textWe}/>) }
    if (store.theme === "service") { return (<ServiceAboutUs data={data} />) }
}