import React, { useEffect } from 'react'
import MinimalProductCard from './MinimalProductCard'
import MinimalProductSpecialCategory from './MinimalProductSpecialCategory'

export default function MinimalGridXL({ data, nameCategory }) {
  return (
    <div className='container'>
      {/* <div className="container pb-12 mb-12 border-b border-gray-300"> */}
      {/* <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2"> */}
      <div className='grid gap-6 mb-6 grid-cols-2 lg:px-0'>
        {data.map((product, index) => {
          return (
            <MinimalProductSpecialCategory
              xl
              key={index}
              id={product._id}
              imagenproducto={product.imagenproducto}
              image={product.image}
              name={product.nombreproducto}
              price={product.precio}
              codigoproducto={product.codigoproducto}
            />
          )
        })}
      </div>
    </div>
  )
}
