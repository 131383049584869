import React from "react";
import MinimalProductCard from "./MinimalProductCard";

export default function MinimalProductsList({ products, name, id, index }) {
    
    
    return (
        <div className="pb-4 mt-8 mb-12 border-b border-gray-300" id={`ProductsContainer-${id}-${index}`} >
            <div id="gridProductLg" className="font-serif text-2xl tracking-wider text-gray-500 ">{name}</div>
            <div className="grid grid-cols-2 gap-6 py-4 overflow-x-scroll sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 no-scrollbar" >
                {products.length > 0 ? products.map((product, index) => {
                    return (
                        <MinimalProductCard  md key={index} id={product.id} imagenproducto={product.imagenproducto} image={product.image} name={product.nombreproducto} price={product.precio} />
                    )
                })
                    : "no hay productos"
                }
            </div>
        </div>
    );
}