import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

export default function ServiceContact({ data }) {

  return (
    <div className="flex">
      <div className="absolute top-0 bottom-0 right-0 hidden w-2/3 bg-cover md:flex -z-1" style={{ backgroundImage: `url(${data.primeraimagennosotros})` }}></div>
      <div className="container min-h-screen pt-24">
        <div className="flex flex-col justify-start h-auto lg:flex-row">
          <div className="flex flex-col justify-center flex-grow max-w-sm p-4 ml-10 lg:pt-12">
            <div className="items-center mb-12 text-2xl font-semibold tracking-tighter text-black">Contáctanos</div>
            <div className="flex flex-col mb-4 tracking-tight">
              <p>Correo: <span className="font-bold">{data.correoelectronico}</span> </p>
              <p>Teléfonos: <span className="font-bold">{data.telefono}</span> </p>
            </div>
            <div className="flex flex-col tracking-tight">
              <p className="font-semibold tracking-tighter text-black">O síguenos en nuestras redes:</p>
              <a href={data ? data.socialmedia.facebook : '#'} target="_blank" rel="noreferrer" className="flex items-center gap-4 text-base font-light"><FontAwesomeIcon icon={faFacebookF} /> Facebook</a>
              <a href={data ? data.socialmedia.twitter : '#'} target="_blank" rel="noreferrer" className="flex items-center gap-4 text-base font-light"><FontAwesomeIcon icon={faTwitter} />Twitter</a>
              <a href={data ? data.socialmedia.instagram : '#'} target="_blank" rel="noreferrer" className="flex items-center gap-4 text-base font-light"><FontAwesomeIcon icon={faInstagram} />Instagram</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}