import React from 'react'
import { Link } from 'react-router-dom'

const ErrorMx = () => {
  return (
    // <div class='h-screen  py-16   px-32 flex gap-5 justify-content-between'>
    //   <div className='mt-auto'></div>
    //   <div className=' flex flex-col items-center justify-center'>
    //     <div
    //       class='relative rounded-full  w-16 h-16'
    //       style={{ background: '#8163FF' }}
    //     >
    //       <div class='inline-flex justify-center items-center w-full h-full'>
    //         <div class='transform rotate-45 absolute w-8 h-1 bg-white'></div>
    //         <div class='transform -rotate-45 absolute w-8 h-1 bg-white'></div>
    //       </div>
    //     </div>{' '}
    //     <h2> Hubo un fallo en tu transacción</h2>
    //     <Link to={'nueva-tienda/checkout'}>
    //       <button className='theme-btn-one'>Volver</button>
    //     </Link>
    //   </div>
    //   <div></div>
    // </div>

    <div className='flex'>
      <div className='container pt-8'>
        <h3 className='mt-12 text-5xl font-bold tracking-tighter'>
          Hubo un fallo en la transaccion{' '}
        </h3>
        <div className='flex justify-start w-full my-10'>
          <Link
            to={'/'}
            className='px-10 py-3 font-semibold rounded-lg cursor-pointer w-fit bg-primary'
          >
            Volver
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ErrorMx
