import { faFacebookF, faInstagram, faTwitter, faWhatsapp, } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";

export default function ServiceFooter() {
    const { store, setStore } = useContext(StoreContext);
    const [userdata, setUserdata] = useState({});
    const [loader, setloader] = useState(true);

    return (
        <div className='w-full px-4 py-8 text-white bg-black '>
            {loader ? (
                <div></div>
            ) : (
                <div className='container grid grid-cols-1 gap-6 mb-6 font-serif md:grid-cols-3 '>
                    <div className='grid justify-center grid-cols-1 gap-4 '>
                        <div className='text-2xl '>
                            Contáctanos:
                        </div>

                        <div className='flex flex-row gap-4 '>
                            <div className='font-bold text-gray-700 '>Teléfonos: </div>
                            <div className='text-base '>{store.telefono}</div>
                        </div>

                        <div className='grid grid-cols-1 gap-2'>
                            <div className='flex flex-col gap-4'>
                                {userdata.socialmedia.facebook &&
                                    userdata.socialmedia.facebook !== "" ? (
                                    <a
                                        href={userdata ? userdata.socialmedia.facebook : "#"}
                                        target='_blank'
                                        className='flex items-center gap-4 text-base '
                                    >
                                        <FontAwesomeIcon icon={faFacebookF} /> Facebook
                                    </a>
                                ) : (
                                    ""
                                )}
                                {userdata.socialmedia.facebook &&
                                    userdata.socialmedia.twitter !== "" ? (
                                    <a
                                        href={userdata ? userdata.socialmedia.twitter : "#"}
                                        target='_blank'
                                        className='flex items-center gap-4 text-base '
                                    >
                                        <FontAwesomeIcon icon={faTwitter} />
                                        Twitter
                                    </a>
                                ) : (
                                    ""
                                )}
                                {userdata.socialmedia.facebook &&
                                    userdata.socialmedia.instagram !== "" ? (
                                    <a
                                        href={userdata ? userdata.socialmedia.instagram : "#"}
                                        target='_blank'
                                        className='flex items-center gap-4 text-base '
                                    >
                                        <FontAwesomeIcon icon={faInstagram} />
                                        Instagram
                                    </a>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                    <div></div>
                    <div className='grid grid-cols-1 md:col-start-3 md:col-end-4'>
                        <div className='flex flex-col gap-4 text-sm '>
                            <Link
                                to={"/productos"}
                                className='py-1 border-b-2 border-gray-300'
                            >
                                Productos
                            </Link>
                            {/* <Link to={'/'} className="py-1 border-b-2 border-gray-300">Ayuda</Link> */}
                            {/* <Link to={'/'} className="py-1 border-b-2 border-gray-300">Servicios</Link> */}
                            <Link
                                to={"/nosotros"}
                                className='py-1 border-b-2 border-gray-300'
                            >
                                Nosotros
                            </Link>
                            <Link to={"/contacto"} className='py-1 border-gray-300'>
                                Contacto
                            </Link>
                        </div>
                    </div>
                    {userdata.whatsapp && userdata.whatsapp !== "" ? (
                        <div className='fixed z-20 flex items-center justify-center w-24 h-24 bg-green-400 rounded-full shadow-xl bottom-10 right-10'>
                            <a
                                href={userdata ? `https://wa.me/${userdata.whatsapp}` : "#"}
                                target='_blank'
                                className='flex items-center justify-center text-6xl text-white '
                            >
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            )}
            <div className='container'>
                <div className='text-xs font-light '>Página Creada con <a href='https://www.ventafacil.app' target='_blank' className='font-semibold' >VentaFácil 2022</a></div>
            </div>
        </div>
    );
}