import { useEffect, useState } from 'react';
// import { conceptos } from '../utils/data';

export default function UIInputOutSelect ({
    label,
    int = false,
    initialValue = '',
    disabled = false,
    className,
    customname = 'name',
    customvalue = 'value',
    placeholder = '',
    handleChange = () => console.log('selected'),
    options = [],
}) {

    const [value, setValue] = useState('');
    const [onFocus, setOnFocus] = useState(false);
    const [open, setOpen] = useState(false);

    const onChange = (e) => {
        setValue(e.target.value);
        handleChange(e.target.value);
        setOpen(true);
    };

    const selectOption = (e) => {
        setValue(e);
        handleChange(e);
        setOpen(false);
    };

    useEffect(() => {
        setValue(initialValue);
    }, []);
    
    return (
        <div className={`flex  w-full flex-col gap-1 ${className}`}>
            <label className="text-xxs font-bold text-[#535353] tracking-tight">
                {label}
            </label>
            <div className="relative h-[34px] flex">
                {onFocus && (
                    <div className="absolute w-full h-full animate-pulse blur-sm bg-primary-100"></div>
                )}
                <div
                    className={`absolute z-10 flex w-2 h-2 rotate-45 -translate-y-1 border-b-2 border-r-2 right-3 top-1/2 border-primary-500`}
                />
                <div className="absolute flex w-full h-full bg-white rounded ">
                    <input
                        onFocus={() => {
                            setOnFocus(true);
                            setOpen(true);
                        }}
                        onBlur={() => {
                            setOnFocus(false);
                            // setOpen(false);
                        }}
                        className={`p-4 text-xs ${int && 'text-right'}
                        ${
                            disabled
                                ? 'text-slate-400 cursor-not-allowed bg-slate-200 border-slate-200 border'
                                : 'bg-white'
                        } w-full outline-none transition-all rounded `}
                        type="text"
                        value={value}
                        disabled={disabled}
                        placeholder={initialValue ? initialValue : placeholder}
                        onChange={onChange}
                    />
                    {open && (
                        <ul
                            className={`absolute w-full max-h-[250px] overflow-auto top-[38px] z-10 rounded-lg bg-white shadow-xl border border-slate-100`}>
                            {options.map((option, index) => (
                                <li
                                    key={index}
                                    className="p-2 px-4 text-xs cursor-pointer text-slate-600 hover:bg-primary-100"
                                    onClick={() => selectOption(option[customvalue])}>
                                    {option[customname]}
                                    {/* {console.log(option)} */}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};