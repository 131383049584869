import React, { useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes,
  faSearch,
  faCartArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { discountStock } from '../../Utils/PutDiscount'
import { verifyStock } from '../../Utils/VerifyStock'

export default function DeliciousNavbar(props) {
  const location = useLocation()
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const [showResponsiveMenu, setResponsiveMenu] = useState(false)
  const [buildAnimation, setBuildAnimation] = useState(false)
  const { session, setSession } = useContext(SessionContext)
  const openResponsiveMenu = () => {
    setBuildAnimation(!buildAnimation)
    setTimeout(() => {
      setResponsiveMenu(!showResponsiveMenu)
    }, 200)
  }
  const mountedStyle = { animation: 'inAnimation 250ms ease-in' }
  const unmountedStyle = {
    animation: 'outAnimation 270ms ease-out',
    animationFillMode: 'forwards',
  }
  //
  const moveInStyle = { animation: 'slideFromRight 250ms ease-in-out' }
  const moveOutStyle = {
    animation: 'slideFromLeft 270ms ease-in-out',
    animationFillMode: 'forwards',
  }

  const discount = () => {
    verifyStock()
  }

  return (
    <div className='flex md:flex-col  w-full px-2 py-4 bg-white md:px-12 md:gap-2 '>
      <button
        onClick={() => {
          setCart(true)
        }}
        href='/'
        className='ml-auto  hidden md:block  '
      >
        <div
          style={{
            backgroundColor: store.primarycolor
              ? store.primarycolor
              : 'inherit',
            color: store.primarycolor ? '#fff' : 'inherit',
          }}
          className='relative items-center justify-center hidden px-4 py-2 text-lg font-bold rounded-full shadow hover:shadow-2xl bg-opacity-60 md:flex md:gap-2'
        >
          <FontAwesomeIcon icon={faCartArrowDown} /> {props.textCar}
          {session.length > 0 ? (
            <span className='absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full -top-1 -right-1'>
              {session.length}
            </span>
          ) : null}
        </div>
      </button>

      <div className='cursor-pointer md:hidden' onClick={openResponsiveMenu}>
        <FontAwesomeIcon icon={faBars} className='mr-2 text-2xl ' />
      </div>
      <div className='content-center m-auto  w-full flex justify-center  ml-5 md:ml-0 '>
        <Link to='/' className='text-4xl font-bold tracking-tighter    '>
          {store.logo ? (
            <img
              className='h-12  sm:w-60  w-45  object-cover	'
              src={store.logo}
              alt={store?.nombrecomercio}
            ></img>
          ) : (
            <div
              style={{
                color: store.primarycolor ? '#000' : 'inherit',
              }}
            >
              {store?.nombrecomercio}
            </div>
          )}
        </Link>
      </div>

      <div className='flex gap-4 pt-8 m-auto'>
        {props.routes.map((el, index) => {
          return (
            <a key={index} href={el.path}>
              <p
                className={`hidden font-semibold text-xl md:flex uppercase ${
                  location.pathname === el.path
                    ? 'border-b-4 border-red-600'
                    : ''
                }`}
              >
                {el.name}
              </p>
            </a>
          )
        })}
      </div>

      {showResponsiveMenu && (
        <div>
          <div
            className='fixed top-0 bottom-0 left-0 right-0 z-30 w-full pt-12 bg-white shadow-2xl rounded-l-2xl'
            style={buildAnimation ? moveInStyle : moveOutStyle}
          >
            <div
              className='absolute p-4 cursor-pointer top-2 right-2'
              onClick={openResponsiveMenu}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
            <ul className='flex flex-col items-center gap-6 text-xs font-light text-left text-black uppercase'>
              {props.routes.map((el, index) => {
                return (
                  <li key={index} className='text-lg font-black text-center '>
                    <a
                      onClick={() => {
                        setResponsiveMenu(false)
                      }}
                      href={el.path}
                      className='text-lg tracking-tight text-center transition-all cursor-pointer opacity-70 hover:opacity-100'
                    >
                      {el.name}
                    </a>
                  </li>
                )
              })}
              <li
                onClick={() => {
                  setCart(true)
                  setResponsiveMenu(false)
                }}
                href='/'
              >
                <div
                  className='relative items-center justify-center px-4 py-2 text-lg font-bold bg-yellow-300 rounded-full cursor-pointer md:flex'
                  style={{
                    backgroundColor: store.primarycolor
                      ? store.primarycolor
                      : 'inherit',
                    color: store.primarycolor ? '#fff' : 'inherit',
                  }}
                >
                  <p>{props.textCar}</p>
                  {session.length > 0 ? (
                    <span className='absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full -top-1 -right-1'>
                      {session.length}
                    </span>
                  ) : null}
                </div>
              </li>
            </ul>
          </div>

          <div
            style={buildAnimation ? mountedStyle : unmountedStyle}
            className='fixed top-0 bottom-0 left-0 right-0 z-20 bg-white backdrop-filter backdrop-blur-sm bg-opacity-60'
          ></div>
        </div>
      )}
    </div>
  )
}
