import React from "react";

export default function MinimalAbout({ data, textWe }) {

    return (
        <div className="container flex flex-col px-2 py-4 font-serif">
            <div className="flex flex-col items-start justify-start gap-6 lg:flex-col">
                <div className="flex mb-4 text-lg font-semibold tracking-tighter ">{textWe}</div>
                <div className="flex flex-col w-full gap-6">
                    <div className="flex w-full bg-center bg-cover aspect-w-16 aspect-h-9 md:aspect-h-4" style={{ backgroundImage: `url(${data.primeraimagennosotros && data.primeraimagennosotros})` }} ></div>
                    <div className="">{data.info && data.info.info}</div>
                </div>
                <div className="flex flex-col w-full gap-6 md:w-5/6 ">

                    <div className="text-black whitespace-pre-line">{data.about && data.about.info}</div>
                    <div className="flex flex-col items-center text-black">
                        <div className="text-3xl ">{data.info && data.info.address}</div>
                        <div className="text-2xl">{data.info && data.info.district}</div>
                    </div>

                </div>
            </div>
        </div>
    );
}