import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import DeliciousCategoryGrid from "../Components/Delicious/DeliciousCategoryGrid"
import MinimalCategoryGrid from "../Components/Minimal/MinimalCategoryGrid"
import ServiceCategoryGrid from "../Components/Service/ServiceCategoryGrid"

export default function GetCategoriesGrid({data}) {
    const { store, setStore } = useContext(StoreContext)

    if (store.theme === "minimal") { return (<MinimalCategoryGrid data={data} />) }
    if (store.theme === "delicious") { return (<DeliciousCategoryGrid data={data} />) }
    if (store.theme === "service") { return (<ServiceCategoryGrid data={data} />) }
}