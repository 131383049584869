import React from 'react'
import MinimalProductsList from './MinimalProductsList'

export default function MinimalCategoriesList({ data, classname }) {
  return (
    <div className={`container ` + classname} data-aos='fade-up'>
      {data.map((category, index) => {
        return (
          <MinimalProductsList
            key={index}
            products={category.productos}
            id={category._id}
            name={category.nombrecategoria}
          />
        )
      })}
    </div>
  )
}
