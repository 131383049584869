import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import { LanguageContext } from '../Context/LanguageContext'
import GetAboutUs from '../Hooks/GetAboutUs'
import GetFooter from '../Hooks/GetFooter'

export default function Nosotros() {
  const { store, setStore } = useContext(StoreContext)
  const language = useContext(LanguageContext)
  const textWe = language.text.we

  return (
    <>
      <div className='flex flex-col  px-2 pt-5'>
        <GetAboutUs data={store} textWe={textWe} />
      </div>
      <GetFooter />
    </>
  )
}
