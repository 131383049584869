import React, { useEffect }  from "react";
import ServiceProductCard from "./ServiceProductCard";

export default function ServiceGridXL({ data }) {
    return (
        <div className="container pb-12 mt-12 mb-12 border-b border-gray-300">
            <div className="py-12 mb-6 font-serif text-2xl font-bold tracking-tight text-left md:text-3xl">Servicios Recomendados</div>
            <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                {data.map((product, index) => {
                    return (
                        <ServiceProductCard xl
                            key={index}
                            id={product._id}
                            imagenproducto={product.imagenproducto}
                            image={product.image}
                            name={product.nombreproducto}
                            price={product.precio}
                            description={product.description}
                        />
                    )
                })}
            </div>
        </div>
    );
}