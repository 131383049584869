import { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../Context/StoreContext'
import { CleanCupon, GetCuponsByDomainAPI } from '../Utils/GetCuponsByDomain'
import { SessionContext } from '../Context/SessionContext'
import React from 'react'
import { useCouponContext } from '../Context/CouponContext'

export default function UICupons() {
  const { session, setSession } = useContext(SessionContext)
  const cuponActivo = JSON.parse(localStorage.getItem('coupon'))

  const { store } = useContext(StoreContext)

  const {
    changeHandler,
    submitHandler,
    state: { valid, couponSelect, collected },
  } = useCouponContext()

  const [newTotal, setNewTotal] = useState(null)

  return (
    <React.Fragment>
      {cuponActivo?.name === undefined ? (
        couponSelect !== '' ? (
          <div className='py-4'>
            <div className='text-xxs'>tienes un cupon?</div>
            <form className='flex gap-1 my-2' onSubmit={submitHandler}>
              <div className='flex w-full text-xs border border-slate-200'>
                <input
                  className='flex w-full p-3'
                  type='text'
                  placeholder='ingresa tu cupon'
                  onChange={(e) => changeHandler(e)}
                  onSubmit={(e) => submitHandler(e)}
                />
              </div>

              <button
                type='submit'
                disabled={!valid}
                className={`text-xs flex px-2 tracking-tight font-semibold items-center ${
                  !valid
                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                    : 'bg-yellow-400 rounded-md'
                }`}
              >
                Aplicar
              </button>
            </form>
            <div
              className={` text-right text-xxs ${
                collected
                  ? 'text-green-500'
                  : valid
                  ? 'text-blue-500'
                  : 'text-gray-400'
              } `}
            >
              {collected
                ? 'Cupon aplicado'
                : valid
                ? 'CUPON VALIDO'
                : 'CUPON INVALIDO'}
            </div>
            {!collected
              ? valid && (
                  <div className='flex flex-col gap-2 p-4 my-2 rounded-lg bg-slate-100 text-xxs'>
                    <div className='flex justify-between'>
                      Cupon:{' '}
                      <span className='font-bold'>{couponSelect.applyto}</span>
                    </div>
                    <div className='flex justify-between'>
                      Tipo de descuento:{' '}
                      <span className='font-bold'>{couponSelect.benefit}</span>
                    </div>
                    <div className='flex justify-between'>
                      {couponSelect.benefit !== 'freeshipping' &&
                        'Valor del descuento:'}
                      <span className='font-bold'>
                        {couponSelect.benefit === 'percentage'
                          ? couponSelect.value.toFixed(2) + '%'
                          : couponSelect.benefit === 'freeshipping'
                          ? ''
                          : 'S/. ' + couponSelect.value.toFixed(2)}
                      </span>
                    </div>
                  </div>
                )
              : ' '}
          </div>
        ) : (
          ''
        )
      ) : (
        <div className='flex items-center justify-between p-4 bg-green-200 rounded-lg text-xxs'>
          <div className='opacity-50'>Cupón activo</div>
          <div className='text-xs font-bold text-green-700 uppercase'>
            {cuponActivo.name}
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              CleanCupon()
            }}
          >
            eliminar
          </div>
        </div>
      )}
    </React.Fragment>
  )
}
