import React from 'react'
import GetTyC from '../Hooks/GetTyC'
import GetFooter from '../Hooks/GetFooter'

function TerminosyCondiciones() {
  return (
    <div className=' '>
      <GetTyC />
      <GetFooter />
    </div>
  )
}

export default TerminosyCondiciones
