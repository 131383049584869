import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import MinimalSlider from '../Components/Minimal/MinimalSlider'
import DeliciousSlider from '../Components/Delicious/DeliciousSlider'
import ServiceSlider from '../Components/Service/ServiceSlider'

export default function GetSlider({ banners }) {
  const { store, setStore } = useContext(StoreContext)
  if (store.theme === 'delicious') {
    return <DeliciousSlider banners={banners} />
  }
  if (store.theme === 'minimal') {
    return <MinimalSlider banners={banners} />
  }
  if (store.theme === 'service') {
    return <ServiceSlider banners={banners} />
  }
}
