import axios from "axios";

export const getBannersAPI = async (id) => {
  try {
    const { data } = await axios.get(`https://ventafazil-strapi-production.up.railway.app/clientes/${id}`);
    return data.listbanners;
  } catch (error) {
    console.log(error);
  }
};
