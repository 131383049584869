import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { CurrencyContext } from '../../Context/CurrencyContext'

export default function DeliciousSearchBar({ rounded, className }) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency

  return (
    <div
      className={`fixed bottom-0 left-0 z-20 flex flex-col items-center w-full gap-3 p-3 rounded-full md:relative md:mb-6  ${
        rounded ? 'rounded-3xl md:rounded-full' : null
      } md:flex-row bg-primary gap`}
      style={{
        backgroundColor: store.primarycolor ? store.primarycolor : 'inherit',
      }}
    >
      <div className='flex flex-grow w-full gap-2 md:w-auto shrink-0'>
        <div
          style={{ color: store.primarycolor ? '#fff' : 'inherit' }}
          className='flex flex-row items-center gap-2 ml-2 grow '
        >
          <div className='flex flex-row text-sm font-semibold tracking-tighter'>
            {Object.keys(session).length} productos
          </div>
          <div className='flex items-center gap-1 text-lg font-bold tracking-tighter'>
            <span className='text-xs'> {currency}</span>
            {Number(
              session
                .map((x) => x.precio)
                .reduce((a, b) => Number(a) + Number(b), 0)
            ).toFixed(2)}
          </div>
        </div>
        <div
          onClick={() => {
            setCart(true)
          }}
          className='relative flex items-center gap-2 px-4 py-2 font-semibold text-center text-white transition-all bg-black rounded-full cursor-pointer hover:bg-opacity-70 '
        >
          <FontAwesomeIcon icon={faCartArrowDown} /> Hacer Pedido
          {session.length > 0 ? (
            <span className='absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full -top-1 -right-1'>
              {session.length}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  )
}
