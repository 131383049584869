import react, { useContext, useState, useEffect } from 'react'
import { CartContext } from '../Context/CartContext'
import { SessionContext } from '../Context/SessionContext'
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StoreContext } from '../Context/StoreContext'
import { useLocation } from 'react-router-dom'
import { CurrencyContext } from '../Context/CurrencyContext'

export default function UIStorefrontTabs(props) {
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const { cart, setCart } = useContext(CartContext)
  const { store, setStore } = useContext(StoreContext)

  const location = useLocation()

  if (location.pathname === '/checkout/') return null
  if (location.pathname === '/nosotros') return null
  if (location.pathname === '/contacto') return null
  return (
    session.length > 0 && (
      <div
        className='fixed bottom-0 left-0 z-20 flex flex-col items-center w-full gap-3 p-3 rounded-b-none md:hidden md:relative md:mb-6 rounded-3xl md:rounded-full md:flex-row bg-primary gap'
        style={{ backgroundColor: store.primarycolor }}
      >
        {/* <div className="flex items-center flex-grow w-full pl-6 bg-white border rounded-full">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="-ml-2 text-gray-600" />
                <input type="search" name="search" placeholder="Search" className="w-full h-10 px-4 py-2 text-sm border-0 rounded-full focus:border-blue-500" />
            </div> */}
        <div
          className='flex flex-grow w-full gap-2 md:w-auto shrink-0'
          style={{
            backgroundColor: store.primarycolor
              ? store.primarycolor
              : 'inherit',
            color: store.primarycolor ? '#fff' : 'inherit',
          }}
        >
          <div className='flex flex-row items-center gap-2 ml-2 grow '>
            <div className='flex flex-row text-sm font-semibold tracking-tighter'>
              {Object.keys(session).length} productos
            </div>
            <div className='flex items-center gap-1 text-lg font-bold tracking-tighter'>
              <span className='text-xs'> {currency}</span>
              {Number(
                session
                  .map((x) => x.precio)
                  .reduce((a, b) => Number(a) + Number(b), 0)
              ).toFixed(2)}
            </div>
          </div>
          <div
            onClick={() => {
              setCart(true)
            }}
            className='relative flex items-center gap-2 px-4 py-2 font-semibold text-center text-white transition-all bg-black rounded-full cursor-pointer hover:bg-opacity-70 '
          >
            <FontAwesomeIcon icon={faCartArrowDown} /> Hacer Pedido
            {session.length > 0 ? (
              <span className='absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full -top-1 -right-1'>
                {session.length}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    )
  )
}
