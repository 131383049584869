import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { StoreContext } from '../../Context/StoreContext'
import { LanguageContext } from '../../Context/LanguageContext'
import axios from 'axios'

export default function DeliciousFooter({ absolute }) {
  const { store, setStore } = useContext(StoreContext)
  const language = useContext(LanguageContext)
  const textFooter = language.text.footer
  const [userdata, setUserdata] = useState({})
  const [loader, setloader] = useState(true)

  useEffect(() => {
    let config = {
      method: 'get',
      url: `https://ventafazil-strapi-production.up.railway.app/clientes/${store.id}`,
    }
    axios(config)
      .then((response) => {
        setUserdata(response.data)
        setloader(false)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <div
      className='w-full px-4 pb-24 md:pb-4  b-0'
      style={{ background: '#8163FF' }}
    >
      {loader ? (
        <div></div>
      ) : (
        <div className='container flex flex-col justify-center items-center text-white h-40'>
          <div className='grid grid-cols-1 gap-2'>
            <div className='flex  gap-4'>
              {userdata.socialmedia?.facebook &&
              userdata.socialmedia?.facebook !== '' ? (
                <a
                  href={userdata ? userdata.socialmedia?.facebook : '#'}
                  target='_blank'
                  className=' items-center  text-base font-light flex flex-col'
                >
                  <FontAwesomeIcon icon={faFacebookF} className='text-2xl' />{' '}
                  <p className='text-xs'>Facebook</p>
                </a>
              ) : (
                ''
              )}
              {userdata.socialmedia?.twitter &&
              userdata.socialmedia?.twitter !== '' ? (
                <a
                  href={userdata ? userdata.socialmedia?.twitter : '#'}
                  target='_blank'
                  className='flex flex-col items-center  text-base font-light'
                >
                  <FontAwesomeIcon icon={faTiktok} className='text-2xl' />
                  <p className='text-xs'>Tiktok</p>
                </a>
              ) : (
                ''
              )}
              {userdata.socialmedia?.instagram &&
              userdata.socialmedia?.instagram !== '' ? (
                <a
                  href={userdata ? userdata.socialmedia?.instagram : '#'}
                  target='_blank'
                  className='flex flex-col items-center  text-base font-light'
                >
                  <FontAwesomeIcon icon={faInstagram} className='text-2xl' />
                  <p className='text-xs'>Instagram</p>
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='grid grid-cols-1 md:col-start-3 md:col-end-4 mt-2'>
            <div className='flex  gap-4 text-sm '>
              <div className='flex gap-3'>
                <Link to={'/productos'}>{textFooter.products}</Link>
                <div className='pb-1 border-r-2 border-gray-300'></div>
              </div>
              <div className='flex gap-3'>
                <Link to={'/nosotros'}>{textFooter.we}</Link>
                <div className='pb-1 border-r-2 border-gray-300'></div>
              </div>
              <div className='flex gap-2'>
                <Link to={'/contacto'}>{textFooter.contact}</Link>
              </div>
            </div>
          </div>
          <p>Telefonos: {userdata.whatsapp} </p>
          {userdata.whatsapp && userdata.whatsapp !== '' ? (
            <div className='fixed z-20 flex items-center justify-center w-12 h-12 bg-green-400 rounded-full shadow-xl md:w-16 md:h-16 bottom-20 right-6'>
              <a
                href={userdata ? `https://wa.me/${userdata.whatsapp}` : '#'}
                target='_blank'
                className='flex items-center justify-center text-3xl text-white md:text-4xl '
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className='text-3xl md:text-4xl'
                />
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {/* 222 */}
      <div className='text-white'>
        {/* <div className='flex  justify-center gap-4 text-sm'>
          <div className='flex gap-3'>
            {store.libroreclamo && (
              <>
                <Link to={'/libro-de-reclamos'}>{textFooter.claimsBook}</Link>
                <div className='pb-1 border-r-2 border-gray-300'></div>
              </>
            )}{' '}
          </div>
          <div className='flex '>
            <Link to={'/terminos-y-condiciones'}>
              {textFooter.termsAndConditions}
            </Link>
          </div>
        </div> */}
        <div class='flex justify-center'>
          <div class='flex justify-center gap-4 text-sm'>
            <div class='flex text-center '>
              {store.libroreclamo && (
                <>
                  <Link to={'/libro-de-reclamos'}>{textFooter.claimsBook}</Link>
                </>
              )}
            </div>
            {store.libroreclamo && (
              <div class='pb-1 border-r-2 border-gray-300'></div>
            )}{' '}
            <div class='flex text-center'>
              <Link to={'/terminos-y-condiciones'}>
                {textFooter.termsAndConditions}
              </Link>
            </div>
          </div>
        </div>

        <div className='container text-center text-white'>
          <div className='mt-1 text-xs font-light'>
            Sitio web creado por{' '}
            <a href='https://powerbizz.com/' target='_blank'>
              PowerBizz
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
