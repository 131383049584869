import React, { useContext } from "react"
import { StoreContext } from "../Context/StoreContext"
import { LanguageContext } from "../Context/LanguageContext"
import MinimalAbout from "../Components/Minimal/MinimalAbout"
import DeliciousAbout from "../Components/Delicious/DeliciousAbout"
import ServiceAbout from "../Components/Service/ServiceAbout"

export default function GetAbout({ data }) {
    const { store, setStore } = useContext(StoreContext)
    const language = useContext(LanguageContext)
    const textWe = language.text.we
    if (store.theme === "minimal") { return (<MinimalAbout data={data} textWe={textWe}/>) }
    if (store.theme === "delicious") { return (<DeliciousAbout data={data} textWe={textWe}/>) }
    if (store.theme === "service") { return (<ServiceAbout data={data} />) }
}