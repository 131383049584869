import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faWhatsapp,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { StoreContext } from '../../Context/StoreContext'
import { LanguageContext } from '../../Context/LanguageContext'
import axios from 'axios'

export default function MinimalFooter() {
  const { store } = useContext(StoreContext)
  const language = useContext(LanguageContext)
  const textFooter = language.text.footer

  return (
    <div className='w-full px-4 py-12 bg-white '>
      <div className='container grid grid-cols-1 gap-6 mb-6 font-serif md:grid-cols-3 '>
        <div className='grid justify-center grid-cols-1 gap-4 '>
          <div className='text-2xl '>{textFooter.contactUs}</div>

          <div className='flex flex-row gap-4 '>
            <div className='font-bold text-gray-700 '>
              {textFooter.phone}{' '}
            </div>
            <div className='text-base '>{store.telefono}</div>
          </div>

          <div className='grid grid-cols-1 gap-2'>
            <div className='flex flex-col gap-4'>
              {store.socialmedia.facebook &&
                store.socialmedia.facebook !== '' ? (
                <a
                  href={store ? store.socialmedia.facebook : '#'}
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-4 text-base '
                >
                  <FontAwesomeIcon icon={faFacebookF} /> Facebook
                </a>
              ) : (
                ''
              )}
              {store.socialmedia.facebook &&
                store.socialmedia.twitter !== '' ? (
                <a
                  href={store ? store.socialmedia.twitter : '#'}
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-4 text-base '
                >
                  <FontAwesomeIcon icon={faTiktok} />
                  TikTok
                </a>
              ) : (
                ''
              )}
              {store.socialmedia.facebook &&
                store.socialmedia.instagram !== '' ? (
                <a
                  href={store ? store.socialmedia.instagram : '#'}
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-4 text-base '
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  Instagram
                </a>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div></div>
        <div className='grid grid-cols-1 md:col-start-3 md:col-end-4'>
          <div className='flex flex-col gap-4 text-sm '>
            <Link to={'/productos'} className='py-1 border-b-2 border-gray-300'>
              {textFooter.products}
            </Link>
            {/* <Link to={'/'} className="py-1 border-b-2 border-gray-300">Ayuda</Link> */}
            {/* <Link to={'/'} className="py-1 border-b-2 border-gray-300">Servicios</Link> */}
            <Link to={'/nosotros'} className='py-1 border-b-2 border-gray-300'>
              {textFooter.we}
            </Link>
            <Link to={'/contacto'} className='py-1 border-gray-300'>
              {textFooter.contact}
            </Link>
          </div>
        </div>
        {store.whatsapp && store.whatsapp !== '' ? (
          <div className='fixed z-20 flex items-center justify-center  w-10 h-10    md:w-16 md:h-16 bg-green-400 rounded-full shadow-xl bottom-10 right-10'>
            <a
              href={store ? `https://wa.me/${store.whatsapp}` : '#'}
              target='_blank'
              rel='noreferrer'
              className='flex items-center justify-center  text-4xl text-white '
            >
              <FontAwesomeIcon icon={faWhatsapp} className='text-4xl' />
            </a>
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='container'>
        <div className='mt-4 text-xs font-light'>
          {textFooter.pageCreated?.split('-')[0]}
          <a
            href='https://www.ventafacil.app'
            target='_blank'
            className='font-semibold'
          >
            {' '}
            {textFooter.pageCreated?.split('-')[1]}
          </a>
        </div>
      </div>
    </div>
  )
}
