import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { CurrencyContext } from '../../Context/CurrencyContext'
import { rezizeCloudinary, transformCategory } from '../../Utils/utils'

export default function DeliciousProductDescriptionAlt({
  id,
  nombreproducto,
  imagenproducto,
  precio,
  categoriaproducto,
  detalladoproducto,
  stock,
  image,
  extras,
  variations,
  changeImage,
  codigoproducto,
  categorias,
}) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const [alert, setAlert] = useState(false)
  const [variation, seVariation] = useState(null)
  const [extrasInput, setExtrasInput] = useState(
    new Array(extras ? extras.length : []).fill(false)
  )
  const [totalExtra, setTotalExtra] = useState(0)
  const [dataExtras, setDataExtras] = useState([])
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency

  let changeExtras = (i) => {
    const updatedCheckedState = extrasInput.map((item, index) =>
      index === i ? !item : item
    )
    setExtrasInput(updatedCheckedState)
    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + Number(extras[index].price)
        }
        return sum
      },
      0
    )
    let selectExtras = []
    updatedCheckedState.map((el, i) => {
      if (el === true) {
        selectExtras.push(extras[i])
      }
    })
    setDataExtras(selectExtras)
    setTotalExtra(totalPrice)
  }
  // function AppendToCart(id, name, image, precio, codigoproducto) {
  //   let totalList = variation !== null ? variation.price : precio
  //   let body = {
  //     id: id,
  //     nombreproducto: name,
  //     imagenproducto: image,
  //     precio: Number(totalList) + Number(totalExtra),
  //     codigoproducto: codigoproducto,
  //     variation: variation,
  //     extras: dataExtras,
  //   }

  //   console.log('Mi variation', variation)
  //   let copyProducts = [...session]
  //   copyProducts.push(body)
  //   localStorage.setItem('session', JSON.stringify(copyProducts))
  //   setSession(copyProducts)

  //   setCart(true)
  // }

  function AppendToCart(id, name, image, price, max, categorias) {
    if (!variations) {
      // history.push(`/producto/${id}`)

      const tempItem = session.find((i) => i.id === id)

      if (tempItem) {
        const tempCart = session?.map((cartItem) => {
          if (cartItem.id === id) {
            let newAmount = cartItem.amount + 1

            if (max == '') {
            } else if (newAmount > cartItem.max) {
              newAmount = cartItem.max
            }

            return { ...cartItem, amount: newAmount }
          } else {
            return cartItem
          }
        })
        setSession(tempCart)
        localStorage.setItem('session', JSON.stringify(tempCart))
      } else {
        if (max === 0) {
          return
        }

        let copyProducts = [...session]

        let body = {
          id: id,
          nombreproducto: name,
          imagenproducto: rezizeCloudinary(imagenproducto),
          precio: price,
          amount: 1,
          max,
          categorias,
        }
        copyProducts.push(body)
        setSession(copyProducts)

        localStorage.setItem('session', JSON.stringify(copyProducts))
      }

      setCart(true)
    } else if (variations.length >= 1) {
      let totalList = variation !== null ? variation.price : precio
      let body = {
        id: id,
        nombreproducto: name,
        imagenproducto: image,
        precio: Number(totalList) + Number(totalExtra),
        codigoproducto: codigoproducto,
        variation: variation,
        extras: dataExtras,
      }

      let copyProducts = [...session]
      copyProducts.push(body)
      localStorage.setItem('session', JSON.stringify(copyProducts))
      setSession(copyProducts)
      setCart(true)
      // console.log('Fff')
    } else {
      const tempItem = session.find((i) => i.id === id)

      if (tempItem) {
        const tempCart = session?.map((cartItem) => {
          if (cartItem.id === id) {
            let newAmount = cartItem.amount + 1

            if (max == '') {
            } else if (newAmount > cartItem.max) {
              newAmount = cartItem.max
            }

            return { ...cartItem, amount: newAmount }
          } else {
            return cartItem
          }
        })
        setSession(tempCart)
        localStorage.setItem('session', JSON.stringify(tempCart))
      } else {
        if (max === 0) {
          return
        }
        let copyProducts = [...session]
        let body = {
          id: id,
          nombreproducto: name,
          imagenproducto: rezizeCloudinary(imagenproducto),
          precio: price,
          amount: 1,
          max,
        }
        copyProducts.push(body)
        setSession(copyProducts)

        localStorage.setItem('session', JSON.stringify(copyProducts))
      }

      setCart(true)
    }
  }

  function changeVariation(event) {
    let newdatainfo = variations[event.target.value]
    if (event.target.value === 'null') {
      newdatainfo = null
    }
    seVariation(newdatainfo)
  }

  function signalprice() {
    let result
    if (variation !== null) {
      result = variation.price
    } else {
      result = precio
    }
    return Number(result) + Number(totalExtra)
  }

  return (
    <div className='max-w-sm bg-white shadow-xl shadow-slate-800 rounded-xl'>
      <div className='grid grid-cols-1 gap-2 px-8 py-8'>
        <div className='text-4xl font-semibold tracking-tighter'>
          {nombreproducto}DD
        </div>
        {/* <Link to={`/category/${id}`} className="text-sm font-semibold tracking-tighter">{transformCategory(categoriaproducto)}</Link> */}
        <div className='text-sm font-light whitespace-pre-line'>
          {detalladoproducto}
        </div>
        {variations && variations.length > 0 ? (
          <>
            <div className='text-xs font-semibold tracking-tighter'>
              Variaciones
            </div>
            <select
              onChange={(e) => changeVariation(e)}
              className='w-full p-2 border border-gray-400 rounded-sm'
            >
              {/* <option value="null">Ninguno</option> */}
              {variations &&
                variations.map((variation, i) => {
                  return (
                    <option key={i} value={i}>
                      {variation.name}
                    </option>
                  )
                })}
            </select>
          </>
        ) : (
          ''
        )}
      </div>
      <div className='grid grid-cols-1 gap-4 px-8 py-8 bg-black rounded-b-xl'>
        {extras && extras.length > 0 ? (
          <>
            <div className='font-semibold text-white'>Extras</div>
            <div className='flex flex-col'>
              {extras &&
                extras.map((extra, i) => {
                  return (
                    <div
                      key={i}
                      className='flex flex-row justify-between text-white'
                    >
                      <label>
                        <input
                          className='mr-1'
                          type='checkbox'
                          id={i}
                          // name={name}
                          // value={name}
                          // checked={checkedState[index]}
                          onChange={() => changeExtras(i)}
                        />
                        {extra.name}
                      </label>
                      <div className=''>+ {currency + ' ' + extra.price}</div>
                    </div>
                  )
                })}
            </div>
          </>
        ) : (
          ''
        )}
        <div className='grid grid-cols-1 '>
          <div className='grid justify-start grid-cols-3 gap-2 mb-6'>
            {image &&
              image.map((image, index) => (
                <div
                  key={index}
                  onClick={() => changeImage(image)}
                  style={{ backgroundImage: `url(${image.url})` }}
                  className='flex flex-shrink-0 w-full h-24 bg-center bg-cover border border-white cursor-pointer'
                ></div>
              ))}
          </div>
          <div className='flex flex-row justify-between gap-2 text-white'>
            <div className='opacity-50'>{stock} En stock</div>
            <div>{currency + ' ' + signalprice()}</div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <div
            style={{
              backgroundColor: store.primarycolor
                ? store.primarycolor
                : 'inherit',
              color: store.primarycolor ? '#fff' : 'inherit',
            }}
            className="px-4 py-2 text-lg font-bold rounded-full cursor-pointer bg-primary flex flex-row after:opacity-0 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex"
            onClick={() => {
              AppendToCart(
                id,
                nombreproducto,
                imagenproducto,
                precio,
                stock,
                categorias
              )
            }}
            id={id}
          >
            Agregar al carrito
          </div>
          {alert ? (
            <div className='flex items-center gap-2 p-2 text-green-300'>
              <FontAwesomeIcon icon={faCheck} /> producto agregado
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
