import axios from "axios";

export const getCategoriesAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `https://ventafazil-strapi-production.up.railway.app/categorias/?cliente=${id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
