import React, { useContext, useState } from 'react'
import { GetComplaintsBook } from '../Utils/GetComplaintsBook'
import { StoreContext } from '../Context/StoreContext'
import UIInputOutSelect from '../Components/UI/UIInputOutSelect'
import UICheckout from '../Components/UI/UICheckbox'
import UICheckbox from '../Components/UI/UICheckbox'
import { useEffect } from 'react'

function LibrodeReclamos() {
  const { store, setStore } = useContext(StoreContext)
  const [reclamo, setReclamo] = useState('')
  const [nombre, setNombre] = useState('')
  const [apellido, setApellido] = useState('')
  const [tipodedocumento, setTipodedocumento] = useState('')
  const [domicilio, setDomicilio] = useState('')
  const [telefono, setTelefono] = useState('')
  const [correo, setCorreo] = useState('')
  const [documentoid, setDocumentoid] = useState('')
  const [error, setError] = useState(false)
  const [result, setResult] = useState('')
  const [conceptos, setConceptos] = useState([
    { name: 'DNI', value: 'DNI' },
    { name: 'Pasaporte', value: 'Pasaporte' },
    { name: 'Carnet de extranjería', value: 'Carnet de extranjería' },
  ])
  const [selection, setSelection] = useState('')

  const [isProducto, setisProducto] = useState(true)
  const [check, setCheck] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [check4, setCheck4] = useState(false)
  const [check5, setCheck5] = useState(false)
  const [check6, setCheck6] = useState(false)
  const [detalle1, setDetalle1] = useState('')
  const [detalleRQ, setDetalleRQ] = useState('')
  const [detalleAccion, setDetalleAccion] = useState('')

  function changeHandlerNombre(e) {
    setNombre(e)
  }
  function changeHandlerApellido(e) {
    setApellido(e)
  }

  function changeHandlerDomicilio(e) {
    setDomicilio(e)
  }
  function changeHandlerTelefono(e) {
    setTelefono(e)
  }
  function changeHandlerCorreo(e) {
    setCorreo(e)
  }
  function changeHandlerDocumentoid(e) {
    setDocumentoid(e)
  }

  function changeHandlerDetalle1(e) {
    setDetalle1(e)
  }
  function changeHandlerDetalleRQ(e) {
    setDetalleRQ(e)
  }
  function changeHandlerDetalleAccion(e) {
    setDetalleAccion(e)
  }

  function SubmitForm(event) {
    event.preventDefault()
    let storemail = store.correoelectronico
    GetComplaintsBook(
      reclamo,
      nombre,
      apellido,
      tipodedocumento,
      documentoid,
      correo,
      telefono,
      detalle1,
      detalleRQ,
      detalleAccion,
      storemail,
      selectedOption,
      selectedOption1
    ).then((data) => {
      if (data) {
        setResult(data)
        setReclamo(true)
        console.log(data)
      } else {
        setError(true)
      }
    })
  }

  const handleChangeSelection = (e) => {
    setTipodedocumento(e) //concepto
  }

  const handleChangeAjusteManual = (e) => {
    setCheck(e.target.checked)
    // console.log(e.target.checked);
  }

  const handleChangeAjusteManual2 = (e) => {
    setCheck2(e.target.checked)
    // console.log(e.target.checked);
  }

  const handleChangeAjusteManual3 = (e) => {
    setCheck3(e.target.checked)
    // console.log(e.target.checked);
  }

  const handleChangeAjusteManual4 = (e) => {
    setCheck4(e.target.checked)
    // console.log(e.target.checked);
  }

  const handleChangeAjusteManual5 = (e) => {
    setCheck5(e.target.checked)
    // console.log(e.target.checked);
  }

  const handleChangeAjusteManual6 = (e) => {
    setCheck6(e.target.checked)
    // console.log(e.target.checked);
  }

  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedOption1, setSelectedOption1] = useState(null)

  const handleOptionChange = (event) => {
    if (event.target.name === 'contrato') {
      setSelectedOption(event.target.value)
    }
    if (event.target.name === 'contrato1') {
      setSelectedOption1(event.target.value)
    }
  }

  //   useEffect(() => {}, [selectedOption])

  return (
    <div className='container flex flex-col px-8 py-12'>
      {reclamo ? (
        <React.Fragment>
          <div className='flex flex-row justify-center align-middle '>
            ¡Gracias!, en breve nos pondremos en contacto contigo para atender
            tu reclamo.
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            data-aos='fade-up'
            className='mb-12 text-3xl font-semibold tracking-tighter text-center text-gray-700'
          >
            Libro de reclamación
          </div>
          <div className='  px-4  md:px-32 mb-8 tracking-tighter text-center text-gray-700'>
            Conforme al código de Protección y Defensa del Consumidor, ponemos a
            tu disposición el Libro de Reclamaciones en caso desees ingresar un
            reclamo o queja respecto al servicio o producto recibido.
          </div>
          <div>
            <form
              onSubmit={(e) => SubmitForm(e)}
              className='flex flex-col items-center justify-center gap-4 '
            >
              <div className='flex flex-col w-4/4 md:w-3/4  gap-4 text-left'>
                <div className='mt-8 font-bold'>
                  {' '}
                  1.- Identificación del Consumidor Reclamante
                </div>
                <div className='w-4/4 md:w-3/4 '>
                  <input
                    onChange={(e) => changeHandlerNombre(e.target.value)}
                    type='text'
                    name='name'
                    placeholder=' Nombre'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Se necesita un nombre
                  </span>
                </div>

                <div className='w-4/4 md:w-3/4 '>
                  <input
                    onChange={(e) => changeHandlerApellido(e.target.value)}
                    type='text'
                    name='Lastname'
                    placeholder='Apellido'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Se necesita un apellido
                  </span>
                </div>
                <div className='flex w-4/4 md:w-3/4  gap-4'>
                  <UIInputOutSelect
                    label='Documento'
                    placeholder='DNI'
                    value={tipodedocumento}
                    options={conceptos}
                    initialValue={conceptos[0].value}
                    handleChange={handleChangeSelection}
                    className='w-1/4'
                  />

                  <div className='w-4/4 md:w-3/4 '>
                    <input
                      onChange={(e) => changeHandlerDocumentoid(e.target.value)}
                      type='text'
                      name='Lastname'
                      placeholder='N° de documento'
                      required
                      className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                    />

                    <span className='hidden text-sm text-red-600' id='error'>
                      Se necesita un Nº de documento
                    </span>
                  </div>
                </div>

                <div className='w-4/4 md:w-3/4  '>
                  <input
                    onChange={(e) => changeHandlerCorreo(e.target.value)}
                    type='text'
                    name='mail'
                    placeholder='Correo electrónico'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Es necesario un correo
                  </span>
                </div>

                <div className='w-4/4 md:w-3/4 '>
                  <input
                    onChange={(e) => changeHandlerTelefono(e.target.value)}
                    type='text'
                    name='telefono'
                    placeholder='telefono'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Se necesita un número de teléfono.
                  </span>
                </div>
              </div>
              <div className='flex flex-col w-3/4 gap-4 text-left'>
                <div className='mt-8 font-bold'>
                  2.- Identificación del bien contratado
                </div>

                <div className='flex w-4/4 md:w-3/4  gap-4'>
                  <div className='flex items-center w-1/2 justify-items-center'>
                    <UICheckbox
                      handleOptionChange={handleOptionChange}
                      selectedOption={selectedOption}
                      option='Producto'
                      value='Producto'
                      name='contrato'
                    />
                    <div className='flex '> Producto</div>
                  </div>
                  <div className='flex items-center w-1/2 justify-items-center'>
                    <UICheckbox
                      handleOptionChange={handleOptionChange}
                      selectedOption={selectedOption}
                      option='Servicio'
                      value='Servicio'
                      name='contrato'
                    />
                    <div className='flex '> Servicio</div>
                  </div>
                </div>

                <div className='w-4/4 md:w-3/4 '>
                  <input
                    onChange={(e) => changeHandlerDetalle1(e.target.value)}
                    type='text'
                    name='Detalle'
                    placeholder='Detalle'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Se necesita el detalle
                  </span>
                </div>
              </div>
              <div className='flex flex-col w-3/4 gap-4 text-left'>
                <div className='mt-8 font-bold'>
                  3.- Detalle de la Reclamación
                </div>

                <div className='flex w-4/4 md:w-3/4  gap-4'>
                  <div className='flex items-center w-1/2 justify-items-center'>
                    <UICheckbox
                      handleOptionChange={handleOptionChange}
                      selectedOption={selectedOption1}
                      option='Reclamo'
                      value='Reclamo'
                      name='contrato1'
                    />

                    <div className='flex '> Reclamo</div>
                  </div>
                  <div className='flex items-center w-1/2 justify-items-center'>
                    <UICheckbox
                      handleOptionChange={handleOptionChange}
                      selectedOption={selectedOption1}
                      option='Queja'
                      value='Queja'
                      name='contrato1'
                    />{' '}
                    <div className='flex '> Queja</div>
                  </div>
                </div>

                <div className='w-4/4 md:w-3/4  '>
                  <input
                    onChange={(e) => changeHandlerDetalleRQ(e.target.value)}
                    type='text'
                    name='DetalleRQ'
                    placeholder='Detalle'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Se necesita el detalle
                  </span>
                </div>
                <div>
                  {' '}
                  *Reclamo: disconformidad relacionada a los productos o
                  servicios.
                </div>
                <div>
                  {' '}
                  Queja: Disconformidad no relacionada a los productos o
                  servicios; o, malestar o descontento respecto a la atención al
                  público.
                </div>
              </div>

              <div className='flex flex-col w-3/4 gap-4 text-left'>
                <div className='mt-8 font-bold'>
                  4. Observaciones y acciones adoptadas por el proveedor
                </div>

                {/* <div className='flex w-3/4 gap-4'>
                  <div className='flex items-center w-1/2 justify-items-center'>
                    <UICheckbox handleChange={handleChangeAjusteManual5} />
                    <div className='flex '> Producto</div>
                  </div>
                  <div className='flex items-center w-1/2 justify-items-center'>
                    <UICheckbox handleChange={handleChangeAjusteManual6} />
                    <div className='flex '> Producto</div>
                  </div>
                </div> */}

                <div className='w-3/4 '>
                  <input
                    onChange={(e) => changeHandlerDetalleAccion(e.target.value)}
                    type='text'
                    name='DetalleAccion'
                    placeholder='Detalle'
                    required
                    className='block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black'
                  />

                  <span className='hidden text-sm text-red-600' id='error'>
                    Se necesita el detallado
                  </span>
                </div>
              </div>

              <button
                type='submit'
                className='px-6 py-3 text-lg font-semibold tracking-tighter text-center text-black duration-100 ease-in rounded bg-primary hover:bg-black hover:text-primary'
              >
                Enviar
              </button>
            </form>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default LibrodeReclamos
