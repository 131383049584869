import axios from 'axios'

export const GetLinkClipApi = async (body, clientID) => {
  try {
    const response = await axios.post(
      // `http://localhost:4000/generarclip/${clientID}`,
      `https://vf-express-production.up.railway.app/generarclip/${clientID}`,

      body
    )

    return response
  } catch (error) {
    console.log('fallo express', error)
    return error
  }
}
