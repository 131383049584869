import React, { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { PutVentasAPI } from '../Utils/PutVentas'
import { discountStock } from '../Utils/PutDiscount'
import { CompleteCupon } from '../Utils/GetCuponsByDomain'
import { useContext } from 'react'
import { SessionContext } from '../Context/SessionContext'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
let phoneWsp = JSON.parse(localStorage.getItem('phone'))

const tokenClip =
  'Basic OGFjMWFmNDgtZDdlMS00NThiLWE2MGMtOGVkM2E5NDA5MjUxOmRiNDQxN2NkLTE3ZDAtNDBjMy1iOWE1LWEyZjk0NDFkNTY1Nw=='

const SuccessMx = () => {
  const [loading, setLoading] = useState(true)
  let history = useHistory()
  const sessionContext = useContext(SessionContext)
  const orderID = localStorage.getItem('bolcd')

  const { id } = useParams()

  // useEffect(() => {
  // }, [id])

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    consultarOrder()
  }, [])

  const consultarOrder = async () => {
    setLoading(true)
    if (orderID) {
      const { data } = await axios.get(
        `https://api-gw.payclip.com/checkout/${orderID}`,
        { headers: { 'x-api-key': tokenClip } }
      )
      if (data.status === 'CHECKOUT_COMPLETED') {
        setLoading(false)
        PutVentasAPI(id)
        discountStock()
        sessionContext.setSession([])
        CompleteCupon()
        setTimeout(() => {
          localStorage.removeItem('bolcd')
        }, 2 * 60 * 1000)
      } else {
        history.push('/')
      }
    } else {
      history.push('/')
    }
  }

  return (
    <div className='flex'>
      {loading ? (
        <></>
      ) : (
        <div className='container pt-8'>
          <h3 className='mt-12 text-5xl font-bold tracking-tighter'>
            Felicidades{' '}
          </h3>
          <h2 className='mb-4 text-3xl font-bold tracking-tighter'>
            Gracias por tu compra
          </h2>
          <h4 className='mb-4 text-2xl font-semibold tracking-tighter'>
            Recibirás un correo por parte de la pasarela de pago como
            confirmación del pago exitoso.{' '}
          </h4>
          <br />
          <br />
          {phoneWsp !== 'undefined' ? (
            <div>
              <h4 className='mb-4 text-2xl font-semibold tracking-tighter'>
                Puedes hacer el seguimiento de tu compra aquí.
              </h4>
              <div className='flex justify-start w-full my-10'>
                <a
                  href={phoneWsp ? `https://wa.me/+${phoneWsp}` : '#'}
                  target='_blank'
                  className='px-10 py-3 font-semibold rounded-lg cursor-pointer w-fit bg-primary'
                >
                  WhatsApp
                </a>
              </div>
            </div>
          ) : (
            <div className='flex justify-start w-full my-10'>
              <Link
                to={'/'}
                className='px-10 py-3 font-semibold rounded-lg cursor-pointer w-fit bg-primary'
              >
                Regresar a la tienda
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SuccessMx
