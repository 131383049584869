import React, { useContext, useEffect } from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './MinimalSlider.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { StoreContext } from "../../Context/StoreContext";
import { getBannersAPI } from "../../Utils/GetBanners";
import { CurrencyContext } from "../../Context/CurrencyContext";

export default function MinimalSlider({ data, banners }) {
    const { store, setStore } = useContext(StoreContext)
    const currencyValue = useContext(CurrencyContext)
    const currency = currencyValue.currency
    // const [banners, setBanners] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    // useEffect(() => {
    //     getBannersAPI(store.id).then(response => { setBanners(response); setLoading(false); })
    // }, [])
    return (
        <div className="overflow-hidden">
            {!loading ?
                <Slide>
                    {banners.length > 0
                        ? banners.map((slider, i) => { // USA BANNERS DE EXISTIR 
                            return (
                                <Link to={`/productos`} key={i}>
                                    <div key={i} style={{ backgroundImage: `url(${slider.link ? slider.link : ''})` }}
                                        className={`flex bg-no-repeat flex-col bg-gray-300 relative items-center justify-center bg-center bg-cover h-screen `}>
                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex w-full h-full opacity-100 from-white to-transparent via-transparent bg-gradient-to-br "></div>
                                    </div>
                                </Link>
                            )
                        })
                        : data.map((slider, i) => { // USA PRODUCTOS SI NO HAYBANNERS
                            return (
                                <div key={i} style={{ backgroundImage: `url(${slider.image && slider.image.length > 0 ? slider.image[0].url : ''})` }}
                                    className={`flex bg-no-repeat flex-col bg-gray-200 relative items-center justify-center bg-center bg-cover h-screen `}>
                                    <div className="font-serif leading-tight text-white text-7xl">{slider.nombreproducto}</div>
                                    <Link to={`/producto/${slider.id}`} className="z-10 flex flex-row items-center gap-2 p-3 py-2 font-serif text-lg text-gray-700 bg-white ">
                                        <span className="flex flex-row items-center gap-2 text-3xl ">
                                            <span className="text-sm font-bold">{currency} </span>
                                            <span>{Number(slider.precio).toFixed(2)}</span>
                                            <FontAwesomeIcon icon={faShoppingCart} className="text-lg text-gray-400 " />
                                        </span>
                                        <div className="absolute top-0 bottom-0 left-0 right-0 flex w-full h-full opacity-100 from-transparent bg-gradient-to-tl to-black"></div>
                                    </Link>
                                </div>
                            )
                        }
                        )}
                </Slide>
                :
                <div></div>
            }
        </div>
    );
}