import React, { useContext } from 'react'
import { StoreContext } from '../Context/StoreContext'
import MinimalGridXL from '../Components/Minimal/MinimalGridXL'
import DeliciousGridXL from '../Components/Delicious/DeliciousGridXL'
import ServiceGridXL from '../Components/Service/ServiceGridXL'

export default function GetCategory({ data, category, cate }) {
  const { store, setStore } = useContext(StoreContext)

  const categorias = [
    {
      _id: cate._id,
      nombrecategoria: cate.nombrecategoria,
    },
  ]
  if (store.theme === 'delicious') {
    return (
      <DeliciousGridXL data={data} category={true} categorias={categorias} />
    )
  }
  if (store.theme === 'minimal') {
    return <MinimalGridXL data={data} />
  }
  if (store.theme === 'service') {
    return <ServiceGridXL data={data} />
  }
}
