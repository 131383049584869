import { createContext, useState } from "react";

const CurrencyContext = createContext()

const CurrencyProvider = ({ children }) => {
    const [currency, setCurrency] = useState('S/')

    const changeCurrency = (val) => {
        let _val = val?.split(',')[0]
        switch (_val) {
            case 'S/':
                setCurrency(_val)
                break;
            case '$':
                setCurrency(_val)
                break;
            default:
                setCurrency('S/')
        }
    }

    const value = { currency, changeCurrency }

    return (
        <CurrencyContext.Provider value={value}>
            {children}
        </CurrencyContext.Provider>
    )
}


export { CurrencyContext, CurrencyProvider }