import {
  faCheck,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { SessionContext } from "../Context/SessionContext";
import React, { useContext, useState } from 'react'
import { SessionContext } from '../Context/SessionContext'
import { CurrencyContext } from '../Context/CurrencyContext'
import { LanguageContext } from '../Context/LanguageContext'
import AmountButtons from './AmountButtons'
import { text } from '@fortawesome/fontawesome-svg-core'
export default function UIStoreforntCartContentsCell(props) {
  const [confirm, setConfirm] = React.useState(false)
  const [deleted, setDeleted] = React.useState(null)
  const { session, setSession } = useContext(SessionContext)
  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const language = useContext(LanguageContext)
  const textCar = language.text.car
  function deleteProduct(val) {
    setConfirm(val)
  }

  // const variacionesProd = Object.entries(props.variation).map((i) => i[1])

  function dangerouslyDeleteProduct() {
    //borrar del contexto session usuario
    props.deleteProduct(props.index)
    setConfirm(false)
    setDeleted(true)
  }

  const renderVariations = () => {
    let content = []
    for (const property in props.variation) {
      content.push(
        <li>
          {property}: {props.variation[property]}
        </li>
      )
    }
    return content
  }

  return (
    // deleted !== null ?
    //     <div className="flex items-center w-full gap-2 p-4 overflow-hidden text-gray-400 transition-all duration-500 transform bg-gray-200 rounded-md after:duration-500 after:-ml-3 ">
    //         <div className="flex items-center gap-3 cursor- ">
    //             <FontAwesomeIcon icon={faCheck} />
    //             <div>Producto eliminado</div>
    //         </div>
    //     </div>
    //     :
    <div className='flex items-center w-full gap-1 p-4 overflow-hidden transition-all duration-500 transform bg-black rounded-md after:duration-500 bg-opacity-5 hover:shadow-xl hover:-translate-y-1 hover:bg-white cursor'>
      <div className='w-12 overflow-hidden text-sm rounded shrink-0 '>
        <div
          className='flex w-12 h-12 overflow-hidden bg-cover '
          style={{ backgroundImage: `url(${props.imagen})` }}
        ></div>
      </div>
      <div className='flex flex-col justify-start flex-grow'>
        <div
          className={` mb-1 ${
            props.theme && props.theme === 'mini'
              ? 'text-xs px-2'
              : 'text3xl px-4'
          } font-bold tracking-tight`}
        >
          {props.nombre}
        </div>
        <div className='font-mono text-xs' type='text'>
          {props.codigo}
        </div>
        {/* {props.variation && props.variation.name && (
          <p
            className={` mb-1 ${
              props.theme && props.theme === 'mini'
                ? 'text-xs px-2'
                : 'text3xl px-4'
            } font-bold tracking-tight`}
          >
            <span>Variación : </span>
            {props.variation?.name}
          </p>
        )} */}
        {renderVariations()}
        {props?.extras && props.extras.length > 0 && (
          <p
            className={` mb-1 ${
              props.theme && props.theme === 'mini'
                ? 'text-xs px-2'
                : 'text3xl px-4'
            } font-bold tracking-tight`}
          >
            <span>
              +{props.extras.length} {textCar.extra}{' '}
            </span>
          </p>
        )}
        <div>
          {textCar.amount} {props.amount || 1}
        </div>
        {/* {props.id2 && (
          <AmountButtons
            amount={props.amount}
            decrease={() => {
              props.decrease(props.id1)
            }}
            increase={() => {
              props.increase(props.id1, props.id2)
            }}
          />
        )} */}
      </div>
      <div className='flex items-center self-end gap-1 text-lg font-semibold tracking-tighter   text-green-500'>
        <span className='text-xs'>{currency}</span>{' '}
        {Number(props.precio).toFixed(2)}
      </div>
      <div
        onClick={() => deleteProduct(true)}
        className='absolute top-0 flex items-center gap-2 p-4 text-lg font-bold text-gray-500 uppercase cursor-pointer right-1 hover:text-red-600 '
      >
        <FontAwesomeIcon className='text-xs' icon={faTimes} />
      </div>
      {confirm ? (
        <div className='absolute top-0 left-0 flex flex-col items-center justify-center w-full h-full gap-2 text-white bg-black bg-opacity-90'>
          <div className='gap-2 font-semibold'>
            <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
            {textCar.delete.title}
          </div>
          <div className='flex gap-2'>
            <button
              className='opacity-70 hover:opacity-100'
              onClick={() => deleteProduct(false)}
            >
              {textCar.delete.btnCancel}
            </button>
            <button
              className='p-2 px-3 text-white transition-all bg-red-500 rounded-lg hover:bg-red-400'
              onClick={dangerouslyDeleteProduct}
            >
              {textCar.delete.btnAccept}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}
