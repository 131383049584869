import React, { Component } from "react";
import axios from "axios";
import { StoreContext } from "../../Context/StoreContext";

export default function ServiceAboutUs({ data }) {
    return (
        <div className="container flex flex-col py-4 font-sans">
            <div className="flex mb-6 font-serif text-2xl font-bold tracking-tighter capitalize ">Nosotros</div>
            <div className="flex flex-row items-start gap-6 pb-12 ">
                <div className="flex flex-col w-2/3 ">
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-grow w-full bg-center bg-cover h-144" style={{ backgroundImage: `url(${data.primeraimagennosotros && data.primeraimagennosotros})` }} ></div>
                        <div className="">{data.about && data.about.info}</div>
                    </div>
                </div>
                <div className="flex flex-col justify-start w-1/3 gap-6 leading-0 ">
                    <div className="flex flex-col gap-0 font-sm">
                        <div className="font-sans text-2xl font-bold tracking-tighter">Local</div>
                        <div>{data.about && data.about.address}</div>
                        <div>{data.about && data.about.district}</div>
                    </div>
                    <div className="flex flex-col text-sm">
                        <div className="font-sans text-2xl font-bold tracking-tighter">Horario</div>
                        <div>{data.about.timeatention && data.about.timeatention}</div>
                    </div>
                    <div className="font-bold tracking-tighter">{true ? "Closed now" : "Open Now"}</div>
                    <a className="p-3 text-sm text-center text-white bg-black rounded-lg" href={`mailto:${data.correoelectronico}`}>Contáctanos</a>
                </div>
            </div>
        </div>
    );
}
