import React, { useContext, useState, useEffect } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Home from '../Views/Home'
import Nosotros from '../Views/Nosotros'
import SearchResults from '../Views/SearchResults'
import Producto from '../Views/Producto'
import Contacto from '../Views/Contacto'
import UIStorefrontCart from '../Components/UIStorefrontCart'
import Carrito from '../Views/Carrito'
import ThankYou from '../Views/ThankYou'
import Category from '../Views/Category'
import Categories from '../Views/Categories'
import CategoriesSecondv from '../Components/DeliciusDual/CategoriesSecondv'
import UIStorefrontTabs from '../Components/UIStoreFrontTabs'
import { StoreContext } from '../Context/StoreContext'
import { GetStoreByDomainAPI } from '../Utils/GetStoreByDomain'
import { SessionContext } from '../Context/SessionContext'
import { LanguageContext } from '../Context/LanguageContext'
import { CurrencyContext } from '../Context/CurrencyContext'
import CheckoutFunction from '../Views/Checkout.Function'
import GetNavbar from '../Hooks/GetNavbar'
import { Helmet } from 'react-helmet'
import TerminosyCondiciones from '../Views/TerminosyCondiciones'
import LibrodeReclamos from '../Views/LibrodeReclamos'
import SuccessMx from '../Views/SuccessMx'
import ErrorMx from '../Views/ErrorMx'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

export default function AppNavigation() {
  const [subdomain, setSubDomain] = useState([
    window.location.host.split('.')[0],
    window.location.host.split('.')[1],
  ]) //PRODUCTION

  // const [subdomain, setSubDomain] = useState('newBetaPW') //LOCAL regalitos-silke
  const { store, setStore } = useContext(StoreContext)
  const [storedata, setStoredata] = useState('')
  const [loader, setLoader] = useState(true)
  const { session } = useContext(SessionContext)
  const language = useContext(LanguageContext)
  const currency = useContext(CurrencyContext)

  useEffect(() => {
    if (subdomain[0] === 'www') {
      GetStoreByDomainAPI(subdomain[1]).then((response) => {
        setStore(response)
        setStoredata(response)
        setLoader(false)

        TagManager.initialize({ gtmId: response.GTM })
        language.changeLanguage(response?.language)
        currency.changeCurrency(response?.currencyvalue)
      })
    }

    // //PRODUCTION
    if (subdomain[1] === 'ventafacil') {
      GetStoreByDomainAPI(subdomain[0]).then((response) => {
        setStore(response)
        setStoredata(response)
        setLoader(false)
        language.changeLanguage(response?.language)
        currency.changeCurrency(response?.currencyvalue)

        TagManager.initialize({ gtmId: response.GTM })
      })
    }
    // PRODUCTION

    if (subdomain[1] === 'powerbizz') {
      GetStoreByDomainAPI(subdomain[0]).then((response) => {
        setStore(response)
        setStoredata(response)
        setLoader(false)
        TagManager.initialize({ gtmId: response.GTM })
        language.changeLanguage(response?.language)
        currency.changeCurrency(response?.currencyvalue)
      })
    }

    // GetStoreByDomainAPI(subdomain).then((response) => {
    //   setStore(response)
    //   console.log('mi response: ', response.metapixelid)
    //   ReactPixel.init(response.metapixelid)

    //   TagManager.initialize({ gtmId: response.GTM })
    //   // ReactPixel.init(response.metapixelid)

    //   setStoredata(response)
    //   setLoader(false)
    // })

    //LOCAL
  }, [])

  return (
    <>
      {loader ? (
        <div></div>
      ) : (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <title>{store.Ntorazonsocial}</title>
            <meta name='description' content={store?.about?.info}></meta>
            <link rel='canonical' href={window.location.host} />
            <meta property='og:title' content={store?.Ntorazonsocial} />
            <meta property='og:type' content='website' />
            <meta property='og:description' content={store.about?.info} />
            <meta property='og:image' content={store?.primeraimagennosotros} />
            <meta property='og:url' content={window.location.host} />
            <meta property='og:locale' content='en_ES' />
          </Helmet>
          <Router>
            <GetNavbar />
            <UIStorefrontCart />

            <UIStorefrontTabs />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/nosotros' component={Nosotros} />
              <Route exact path='/search-results' component={SearchResults} />
              <Route
                exact
                path='/producto/:productid'
                render={(props) => <Producto {...props} />}
              />
              {/* Cambio de version de productos */}
              <Route exact path='/productos/' component={CategoriesSecondv} />
              <Route
                exact
                path='/category/:categoryid'
                render={(props) => <Category {...props} />}
              />
              <Route exact path='/contacto/' component={Contacto} />
              <Route exact path='/carrito/' component={Carrito} />
              <Route exact path='/thankyou' component={ThankYou} />
              <Route
                exact
                path='/redirection/success/:id'
                component={SuccessMx}
              />
              <Route exact path='/redirection/error' component={ErrorMx} />
              <Route exact path='/checkout' component={CheckoutFunction} />

              {/* <Route
                exact
                path='/checkoutfunction'
                render={(props) => (
                  <Checkout
                    {...props}
                    total={
                      Number(
                        session
                          .map((x) => x.precio)
                          .reduce((a, b) => Number(a) + Number(b), 0)
                      ) * 100
                    }
                  />
                )}
              /> */}
              <Route
                exact
                path='/terminos-y-condiciones'
                component={TerminosyCondiciones}
              />
              <Route
                exact
                path='/libro-de-reclamos'
                component={LibrodeReclamos}
              />
            </Switch>
          </Router>
        </>
      )}
    </>
  )
}
