import React, { useContext } from 'react'
import MinimalNavbar from '../Components/Minimal/MinimalNavbar'
import DeliciousNavbar from '../Components/Delicious/DeliciousNavbar'
import ServiceNavbar from '../Components/Service/ServiceNavbar'
import { StoreContext } from '../Context/StoreContext'
import { LanguageContext } from '../Context/LanguageContext'

export default function GetNavbar(props) {
  const { store, setStore } = useContext(StoreContext)
  const language = useContext(LanguageContext)
  const textNavBar = language.text.navBar
  const routes = [
    { path: '/', name: textNavBar?.home },
    { path: '/productos', name: textNavBar?.products },
    { path: '/nosotros', name: textNavBar?.we },
    { path: '/contacto', name: textNavBar?.contact },
  ]
  if (store.theme === 'delicious') {
    return <DeliciousNavbar routes={routes} textCar={textNavBar.car} />
  }
  if (store.theme === 'minimal') {
    return <MinimalNavbar routes={routes} />
  }
  if (store.theme === 'service') {
    return <ServiceNavbar routes={routes} />
  }
}
