import React, { useContext } from 'react'
import UIStoreforntCartContents from './UIStoreforntCartContents'
import { faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CartContext } from '../Context/CartContext'
import { LanguageContext } from '../Context/LanguageContext'
import { Link } from 'react-router-dom'
import { SessionContext } from '../Context/SessionContext'

export default function UIStorefrontCart(props) {
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const language = useContext(LanguageContext)
  const textCar = language.text.car

  return (
    <div
      className={`fixed top-0 right-0  ${
        cart ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
      } z-50 flex flex-col h-screen max-w-sm p-6 transition-all bg-white shadow-black rounded-l-3xl shadow-2xl w-80`}
    >
      <div className='flex flex-col p-0 overflow-auto container-snap'>
        <div
          onClick={() => {
            setCart(false)
          }}
          className='flex items-center justify-between w-full gap-2 mb-4 text-2xl font-semibold tracking-tighter cursor-pointer'
        >
          {textCar.title}
          <FontAwesomeIcon
            className='text-lg justify-self-end'
            icon={faTimes}
          />
        </div>
        <UIStoreforntCartContents theme='mini' />
        {session.length > 0 ? (
          <Link
            onClick={() => setCart(false)}
            to={'/checkout/'}
            className='flex justify-center w-full p-2 px-3 font-semibold text-center transition-all bg-yellow-400 rounded-md shadow-black hover:shadow hover:-translate-y-1 text-md'
          >
            {textCar.makeOrder}
          </Link>
        ) : (
          <div className='flex justify-center w-full p-2 px-3 font-semibold text-center text-gray-400 transition-all bg-gray-300 rounded-md cursor-not-allowed text-md'>
            {textCar.makeOrder}
          </div>
        )}

        <div className='p-4 text-center text-xxs'>
          Secured by <span className='font-bold'>Izipay</span>
        </div>
      </div>
    </div>
  )
}
