import React, { useEffect } from "react";
import MinimalProductCard from "./MinimalProductCard";

export default function MinimalGridXL({ data }) {
    return (
        <div className="container pb-12 mb-12 border-b border-gray-300">
            <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
                {data.map((product, index) => {
                    return (
                        <MinimalProductCard xl
                            key={index}
                            id={product._id}
                            imagenproducto={product.imagenproducto}
                            image={product.image}
                            name={product.nombreproducto}
                            price={product.precio}
                            codigoproducto={product.codigoproducto}
                        />
                    )
                })}
            </div>
        </div>
    );
}