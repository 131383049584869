import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarAlt, faCalendarCheck, faCartArrowDown } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../../Context/CartContext";
import { SessionContext } from "../../Context/SessionContext";

export default function ServiceSearchBar(rounded) {
    const { cart, setCart } = useContext(CartContext);
    const { session, setSession } = useContext(SessionContext);

    return (
        <div className="container ">
            <div className={`fixed bottom-0 md:-mt-24 md:rounded-full left-0 z-20 flex font-sans border-gray-200 border flex-col items-center w-full gap-3 p-2 rounded-b-none md:relative md:mb-6  md:flex-row bg-white gap`}   >
                <div className="flex items-center flex-grow w-full gap-2 md:w-auto shrink-0">
                    <div className="flex flex-col w-20 pl-4 text-sm font-bold leading-3 tracking-tighter">
                        Reserva
                        hoy
                    </div>
                    <div className="flex items-center h-full gap-4 p-3 pl-4 text-sm transition-all border border-gray-300 rounded-full cursor-pointer hover:border-gray-700">
                        <FontAwesomeIcon icon={faCalendarCheck} />
                        Fecha
                    </div>
                    <div className="flex flex-row items-center h-full gap-4 p-3 pl-4 ml-2 text-sm border border-gray-300 rounded-full grow ">
                        <div className="flex flex-row text-sm ">
                            {Object.keys(session).length} productos
                        </div>
                        <div className="flex items-center gap-1 text-lg font-bold tracking-tighter">
                            <span className="text-xs"> S/.</span>{Number(session.map(x => x.precio).reduce((a, b) => Number(a) + Number(b), 0)).toFixed(2)}
                        </div>
                    </div>
                    <div onClick={() => { setCart(true) }} className="relative flex items-center h-full gap-2 px-4 py-3 font-semibold text-center text-white transition-all bg-gray-800 rounded-full cursor-pointer hover:bg-opacity-70 hover:-translate-y-0.5 hover:translate-x-0.5 border hover:bg-white hover:text-black hover:border-black hover:shadow-[-5px_5px_0px_0px_rgba(0,0,0,1)] duration-700 ">
                        Hacer Reserva
                        {session.length > 0 ? <span className="absolute flex items-center justify-center w-5 h-5 text-xs text-white bg-red-500 rounded-full -top-1 -right-1">{session.length}</span> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}