import React, { useContext, useState, useEffect } from 'react'
import { SessionContext } from '../Context/SessionContext'
import { LanguageContext } from '../Context/LanguageContext'
import { CurrencyContext } from '../Context/CurrencyContext'
import { useCouponContext } from '../Context/CouponContext'

export default function UIStorefrontCardTotals(props) {
  const { session, setSession } = useContext(SessionContext)
  const coupon = localStorage.getItem('coupon')

  const currencyValue = useContext(CurrencyContext)
  const currency = currencyValue.currency
  const language = useContext(LanguageContext)
  const textCar = language.text.car
  const {
    state: { newTotal },
  } = useCouponContext()
  useEffect(() => {}, [session])

  return (
    <div className='flex flex-col w-full px-6 py-6 mt-6 mb-1 bg-white rounded-xl'>
      {props.pricedelivery && props.pricedelivery !== null ? (
        <div className='flex justify-between w-full'>
          <div className='pr-10 text-sm'>Costo de envio:</div>
          <div className='text-lg font-bold'>
            {currency + ' '}
            {props.shipping === 'true'
              ? 0
              : Number(props.pricedelivery ? props.pricedelivery : 0)}
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='flex justify-between w-full'>
        <div className='pr-10 text-sm'>{textCar.total}</div>
        {props.pricedelivery !== null ? (
          <div className='flex flex-row items-center gap-2 text-lg font-bold shrink-0'>
            {coupon !== null ? (
              <>
                <div className='text-right opacity-40'>
                  {/* <div className='text-xs line-through '>
                    {currency + ' ' + sumTotal(session)}
                  </div> */}
                  {/* <div className='text-xxs'>cupón aplicado</div> */}
                </div>
                <div className='text-md'>{currency}</div>
                <div className='text-lg'>{newTotal}</div>
              </>
            ) : (
              <>
                <div className='text-sm'>{currency}</div>

                {newTotal}
              </>
            )}
          </div>
        ) : (
          <div className='flex flex-row gap-2 text-lg font-bold'>
            {coupon !== null ? (
              <>
                <div className='text-right line-through opacity-40'>
                  {/* <div className='text-xs'>{currency + ' ' + cartTotal}</div> */}
                  <div className='text-xxs'>cupón aplicado</div>
                </div>
                <div className='text-sm'>{currency}</div>
                {newTotal}
              </>
            ) : (
              <>
                <div className='text-sm'>{currency}</div>
                {newTotal}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
