import React, { Component } from 'react'
import VentaFazilThankYouDetailCell from '../Components/VentaFazilThankYouDetailCell'
import { SessionContext } from '../Context/SessionContext'
import { PutVentasAPI } from '../Utils/PutVentas'
import { Link } from 'react-router-dom'
import { CleanCupon, CompleteCupon } from '../Utils/GetCuponsByDomain'
import { discountStock } from '../Utils/PutDiscount'
export default class ThankYou extends Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      answer: {
        shopId: '73611751',
        orderCycle: 'CLOSED',
        orderStatus: 'PAID',
        serverDate: '2022-02-15T07:07:14+00:00',
        orderDetails: {
          orderTotalAmount: 180,
          orderEffectiveAmount: 180,
          orderCurrency: 'PEN',
          mode: 'TEST',
          orderId: 'myOrderId-123456',
          metadata: null,
          _type: 'V4/OrderDetails',
        },
        customer: {
          billingDetails: {
            address: null,
            category: null,
            cellPhoneNumber: null,
            city: null,
            country: null,
            district: null,
            firstName: null,
            identityCode: null,
            language: 'EN',
            lastName: null,
            phoneNumber: null,
            state: null,
            streetNumber: null,
            title: null,
            zipCode: null,
            legalName: null,
            _type: 'V4/Customer/BillingDetails',
          },
          email: 'jose@aljovin.com',
          reference: null,
          shippingDetails: {
            address: null,
            address2: null,
            category: null,
            city: null,
            country: null,
            deliveryCompanyName: null,
            district: null,
            firstName: null,
            identityCode: null,
            lastName: null,
            legalName: null,
            phoneNumber: null,
            shippingMethod: null,
            shippingSpeed: null,
            state: null,
            streetNumber: null,
            zipCode: null,
            _type: 'V4/Customer/ShippingDetails',
          },
          extraDetails: {
            browserAccept: null,
            fingerPrintId: null,
            ipAddress: '190.237.122.14',
            browserUserAgent:
              'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.82 Safari/537.36',
            _type: 'V4/Customer/ExtraDetails',
          },
          shoppingCart: {
            insuranceAmount: null,
            shippingAmount: null,
            taxAmount: null,
            cartItemInfo: null,
            _type: 'V4/Customer/ShoppingCart',
          },
          _type: 'V4/Customer/Customer',
        },
        transactions: [
          {
            shopId: '73611751',
            uuid: '9ecf8d010c974c93817e338c60d7fd90',
            amount: 180,
            currency: 'PEN',
            paymentMethodType: 'CARD',
            paymentMethodToken: null,
            status: 'PAID',
            detailedStatus: 'AUTHORISED',
            operationType: 'DEBIT',
            effectiveStrongAuthentication: 'DISABLED',
            creationDate: '2022-02-15T07:07:11+00:00',
            errorCode: null,
            errorMessage: null,
            detailedErrorCode: null,
            detailedErrorMessage: null,
            metadata: null,
            transactionDetails: {
              liabilityShift: 'NO',
              effectiveAmount: 180,
              effectiveCurrency: 'PEN',
              creationContext: 'CHARGE',
              cardDetails: {
                paymentSource: 'EC',
                manualValidation: 'NO',
                expectedCaptureDate: '2022-02-15T07:07:11+00:00',
                effectiveBrand: 'VISA',
                pan: '497010XXXXXX0014',
                expiryMonth: 12,
                expiryYear: 2025,
                country: 'PE',
                issuerCode: null,
                issuerName: null,
                effectiveProductCode: 'F',
                legacyTransId: '9c5hql',
                legacyTransDate: '2022-02-15T07:07:11+00:00',
                paymentMethodSource: 'NEW',
                authorizationResponse: {
                  amount: 180,
                  currency: 'PEN',
                  authorizationDate: '2022-02-15T07:07:13+00:00',
                  authorizationNumber: '3feb13',
                  authorizationResult: '0',
                  authorizationMode: 'FULL',
                  _type:
                    'V4/PaymentMethod/Details/Cards/CardAuthorizationResponse',
                },
                captureResponse: {
                  refundAmount: null,
                  refundCurrency: null,
                  captureDate: null,
                  captureFileNumber: null,
                  effectiveRefundAmount: null,
                  effectiveRefundCurrency: null,
                  _type: 'V4/PaymentMethod/Details/Cards/CardCaptureResponse',
                },
                threeDSResponse: {
                  authenticationResultData: {
                    transactionCondition: null,
                    enrolled: null,
                    status: null,
                    eci: null,
                    xid: null,
                    cavvAlgorithm: null,
                    cavv: null,
                    signValid: null,
                    brand: null,
                    _type:
                      'V4/PaymentMethod/Details/Cards/CardAuthenticationResponse',
                  },
                  _type: 'V4/PaymentMethod/Details/Cards/ThreeDSResponse',
                },
                authenticationResponse: null,
                installmentNumber: 0,
                installmentCode: '0',
                markAuthorizationResponse: {
                  amount: null,
                  currency: null,
                  authorizationDate: null,
                  authorizationNumber: null,
                  authorizationResult: null,
                  _type:
                    'V4/PaymentMethod/Details/Cards/MarkAuthorizationResponse',
                },
                cardHolderName: null,
                identityDocumentNumber: null,
                identityDocumentType: null,
                _type: 'V4/PaymentMethod/Details/CardDetails',
              },
              paymentMethodDetails: {
                id: '497010XXXXXX0014',
                paymentSource: 'EC',
                manualValidation: 'NO',
                expectedCaptureDate: '2022-02-15T07:07:11+00:00',
                effectiveBrand: 'VISA',
                expiryMonth: 12,
                expiryYear: 2025,
                country: 'PE',
                issuerCode: null,
                issuerName: null,
                effectiveProductCode: 'F',
                legacyTransId: '9c5hql',
                legacyTransDate: '2022-02-15T07:07:11+00:00',
                paymentMethodSource: 'NEW',
                authorizationResponse: {
                  amount: 180,
                  currency: 'PEN',
                  authorizationDate: '2022-02-15T07:07:13+00:00',
                  authorizationNumber: '3feb13',
                  authorizationResult: '0',
                  authorizationMode: 'FULL',
                  _type:
                    'V4/PaymentMethod/Details/Cards/CardAuthorizationResponse',
                },
                captureResponse: {
                  refundAmount: null,
                  refundCurrency: null,
                  captureDate: null,
                  captureFileNumber: null,
                  effectiveRefundAmount: null,
                  effectiveRefundCurrency: null,
                  _type: 'V4/PaymentMethod/Details/Cards/CardCaptureResponse',
                },
                authenticationResponse: null,
                installmentNumber: 0,
                installmentCode: '0',
                markAuthorizationResponse: {
                  amount: null,
                  currency: null,
                  authorizationDate: null,
                  authorizationNumber: null,
                  authorizationResult: null,
                  _type:
                    'V4/PaymentMethod/Details/Cards/MarkAuthorizationResponse',
                },
                cardHolderName: null,
                identityDocumentNumber: null,
                identityDocumentType: null,
                _type: 'V4/PaymentMethod/Details/PaymentMethodDetails',
              },
              fraudManagement: {
                _type: 'V4/PaymentMethod/Details/FraudManagement',
              },
              subscriptionDetails: {
                subscriptionId: null,
                _type: 'V4/PaymentMethod/Details/SubscriptionDetails',
              },
              parentTransactionUuid: null,
              mid: '8996507',
              sequenceNumber: 1,
              taxAmount: null,
              preTaxAmount: null,
              taxRate: null,
              externalTransactionId: '615060',
              dcc: {
                _type: 'V4/DCC',
              },
              nsu: null,
              tid: null,
              acquirerNetwork: 'PROCESOS_ISO',
              taxRefundAmount: null,
              userInfo: 'JS Client',
              paymentMethodTokenPreviouslyRegistered: null,
              occurrenceType: 'UNITAIRE',
              _type: 'V4/TransactionDetails',
            },
            _type: 'V4/PaymentTransaction',
          },
        ],
        subMerchantDetails: null,
        _type: 'V4/Payment',
      },
    }
  }
  componentDidMount() {
    discountStock()

    this.context.setSession([])
    localStorage.setItem('session', JSON.stringify([]))
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    const krAnswer = JSON.parse(query.get('kr-answer'))
    this.setState({ answer: krAnswer })
    let phone = JSON.parse(localStorage.getItem('phone'))
    this.setState({ phone: phone })
    CompleteCupon()
  }
  componentDidUpdate() {
    PutVentasAPI(this.state.answer.orderDetails['orderId'])
  }

  render() {
    return (
      <div className='flex'>
        <div className='container pt-8'>
          <h3 className='mt-12 text-5xl font-bold tracking-tighter'>
            Felicidades{' '}
          </h3>
          <h2 className='mb-4 text-3xl font-bold tracking-tighter'>
            Gracias por tu compra
          </h2>
          <h4 className='mb-4 text-2xl font-semibold tracking-tighter'>
            Recibirás un correo por parte de la pasarela de pago como
            confirmación del pago exitoso.{' '}
          </h4>
          <br />
          <br />
          {this.state.phone && this.state.phone !== 'undefined' ? (
            <div>
              <h4 className='mb-4 text-2xl font-semibold tracking-tighter'>
                Puedes hacer el seguimiento de tu compra aquí.
              </h4>
              <div className='flex justify-start w-full my-10'>
                <a
                  href={
                    this.state.phone || this.state.phone !== undefined
                      ? `https://wa.me/+51${this.state.phone}`
                      : '#'
                  }
                  target='_blank'
                  className='px-10 py-3 font-semibold rounded-lg cursor-pointer w-fit bg-primary'
                >
                  WhatsApp
                </a>
              </div>
            </div>
          ) : (
            <div className='flex justify-start w-full my-10'>
              <Link
                to={'/'}
                className='px-10 py-3 font-semibold rounded-lg cursor-pointer w-fit bg-primary'
              >
                Regresar a la tienda
              </Link>
            </div>
          )}
        </div>
      </div>
    )
  }
}
