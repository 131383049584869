import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faPlus, faShoppingCart, } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../../Context/CartContext";
import { SessionContext } from "../../Context/SessionContext";
import { StoreContext } from "../../Context/StoreContext";

export default function ServiceProductCard({ imagenproducto, description, image, name, price, id, sm, md, xl, overlay, rounded }) {
    const { store, setStore } = useContext(StoreContext);
    const { cart, setCart } = useContext(CartContext);
    const { session, setSession } = useContext(SessionContext);

    function AppendToCart(id, name, image, price) {
        let body = {
            "id": id,
            "nombreproducto": name,
            "imagenproducto": imagenproducto,
            "precio": price,
        };
        let copyProducts = [...session]
        copyProducts.push(body)
        localStorage.setItem('session', JSON.stringify(copyProducts))
        setSession(copyProducts)
        setCart(true)
    }

    if (sm === true) {
        return (
            <div data-aos="fade-up" className="flex flex-col w-full gap-4">
                {md ? null : null}
                <div style={{ backgroundImage: `url(${imagenproducto !== "" ? imagenproducto : image && image.length > 0 ? image[0].url : ''})` }}
                    className="hover:shadow-[-5px_5px_0px_0px_rgba(200,200,200,1)] shadow relative w-full duration-700 bg-center bg-cover border ftransition-all h-128 hover:-translate-y-1 hover:translate-x-1 hover:border-gray-300" >
                    <a href={`/producto/${id}`} className={`absolute font-sans top-0 bottom-0 left-0 right-0`} alt={name} ></a>
                </div>
                <div className="flex flex-row flex-grow gap-2 py-2 font-sans ">
                    <div className="flex flex-col w-full gap-2 ">
                        <a href={`/producto/${id}`}
                            className="items-start justify-start flex-grow w-3/5 p-4 text-2xl leading-5 tracking-tighter text-white capitalize transition-all duration-700 hover:text-black ">
                            {name}
                        </a>
                        <div>
                            <span className="text-xs"> S/</span>
                            {Number(price).toFixed(2)}
                        </div>
                    </div>
                    <div onClick={() => AppendToCart(id, name, image, price)} className=" text-white self-start transition-all hover:bg-black bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 delay-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 py-3 px-3 text-lg font-bold  rounded-full bg-gray-300">
                        <FontAwesomeIcon icon={faAngleRight} className="" />
                        <FontAwesomeIcon icon={faShoppingCart} className="text-md" />
                    </div>
                </div>
            </div>
        )
    }

    if (md === true) {
        return (
            <div className="flex flex-row w-full gap-4 pb-4 transition-all duration-700 border-b border-gray-300 hover:border-black">
                {md ? null : null}
                <div onClick={() => AppendToCart(id, name, image, price)}
                    className="flex flex-row items-center self-start gap-1 p-2 text-lg font-bold text-black transition-all duration-700 border border-gray-500 rounded-full cursor-pointer hover:shadow-[-2px_2px_0px_0px_rgba(0,0,0,1)] hover:translate-x-0.5 hover:-translate-y-0.5 hover:border-black bottom-2 right-2">
                    <FontAwesomeIcon icon={faPlus} className="text-xs" />
                </div>
                <div className="flex flex-row flex-grow gap-2 font-sans ">
                    <a href={`/producto/${id}`}
                        className="items-start justify-start flex-grow w-3/5 pt-1 text-lg font-bold leading-5 tracking-tighter text-black capitalize transition-all duration-700 hover:text-black ">
                        {name}
                    </a>
                    <div>
                        {description}
                    </div>
                </div>
                <div className="pt-1">
                    <span className="text-xs"> S/</span>
                    {Number(price).toFixed(2)}
                </div>
            </div>
        )
    }

    if (xl === true) {
        return (
            <div className="flex flex-col w-full gap-4 font-sans">
                <div style={{ backgroundImage: `url(${imagenproducto !== "" ? imagenproducto : image && image.length > 0 ? image[0].url : ''})` }} className={`hover:shadow-[-5px_5px_0px_0px_rgba(0,0,0,1)] hover:border-black hover:border duration-700 shadow relative flex items-end justify-end overflow-hidden transition-all bg-center bg-cover ${rounded ? "rounded-xl" : null} hover:-translate-y-1  h-128`} >
                    <div className="p-4 text-white bg-black">
                        <span className="text-xs"> S/</span>
                        {Number(price).toFixed(2)}
                    </div>
                    <a href={`/producto/${id}`} className={`${overlay ? "bg-gradient-to-t from-slate-900 via-transparent to-transparent" : null}  absolute top-0 bottom-0 left-0 right-0`}></a>
                </div>
                <div className="flex flex-row">
                    <div className="flex flex-col w-full gap-1">
                        <a href={`/producto/${id}`}
                            className="items-start justify-start flex-grow w-3/5 text-2xl font-bold leading-5 tracking-tighter text-black capitalize transition-all duration-700 hover:text-black ">
                            {name}
                        </a>
                        <div>
                            <a href={`/producto/${id}`} className="text-sm tracking-tight">
                                Ver detalle
                            </a>
                        </div>
                    </div>
                    <div onClick={() => AppendToCart(id, name, image, price)} className="hover:text-black self-start transition-all hover:border-black hover:border bottom-2 cursor-pointer right-2 flex flex-row items-center after:text-sm after:opacity-0 duration-700 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex gap-1 py-3 px-3 text-lg font-bold  rounded-full text-black ">
                        <FontAwesomeIcon icon={faShoppingCart} className="text-md" />
                    </div>
                </div>
            </div>
        )
    }
}