import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext'
import { SessionContext } from '../../Context/SessionContext'
import { StoreContext } from '../../Context/StoreContext'
import { transformCategory } from '../../Utils/utils'

export default function ServiceProductDescription({
  id,
  nombreproducto,
  imagenproducto,
  precio,
  categoriaproducto,
  detalladoproducto,
  stock,
  image,
  extras,
  variables,
  changeImage,
  categorias,
}) {
  const { store, setStore } = useContext(StoreContext)
  const { cart, setCart } = useContext(CartContext)
  const { session, setSession } = useContext(SessionContext)
  const [alert, setAlert] = React.useState(false)

  function AppendToCart(id, name, image, precio, categorias) {
    let body = {
      id: id,
      nombreproducto: name,
      imagenproducto: image,
      precio: precio,
      categorias,
    }
    let copyProducts = [...session]
    copyProducts.push(body)
    localStorage.setItem('session', JSON.stringify(copyProducts))
    setSession(copyProducts)
  }

  return (
    <div className='flex flex-col w-full mt-20 bg-white shadow-xl shadow-gray-0900 rounded-xl'>
      <div className='flex flex-col gap-2 px-8 pb-8'>
        <div
          className='self-center w-32 h-32 -mt-20 bg-cover border border-white rounded-lg'
          style={{ backgroundImage: `url(${imagenproducto})` }}
        ></div>
        <div className='mt-2 text-2xl font-bold tracking-tighter capitalize'>
          {nombreproducto}
        </div>
        <Link
          to={`/category/${id}`}
          className='text-sm font-semibold tracking-tighter'
        >
          {transformCategory(categoriaproducto)}
        </Link>
        <div className='text-sm font-light'>{detalladoproducto}</div>
      </div>
      <div className='grid grid-cols-1 gap-4 px-8 py-8 rounded-b-xl'>
        <div className='grid grid-cols-1 '>
          <div className='flex justify-between'>
            {variables &&
              variables.map((variable, index) => (
                <div
                  key={index}
                  className='text-sm font-semibold tracking-tighter'
                >
                  {variable.nombre}
                </div>
              ))}
          </div>
          <div className='flex flex-row justify-between gap-2 '>
            <div className='font-semibold tracking-tighter'>
              {stock} En stock
            </div>
            <div>S/ {precio}</div>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center text-white bg-black rounded-lg'>
          <div
            style={{
              backgroundColor: store.primarycolor
                ? store.primarycolor
                : 'inherit',
              color: store.primarycolor ? '#fff' : 'inherit',
            }}
            className="px-4 py-2 text-sm font-semibold rounded-full cursor-pointer bg-primary flex flex-row after:opacity-0 after:-mr-4 after:px-1 hover:after:mr-0 hover:after:opacity-100 after:transition-all after:content-['+'] after:flex"
            onClick={() => {
              AppendToCart(
                id,
                nombreproducto,
                imagenproducto,
                precio,
                categorias
              )
            }}
            id={id}
          >
            Agregar al carrito
          </div>
          {alert ? (
            <div className='flex items-center gap-2 p-2 text-green-300'>
              <FontAwesomeIcon icon={faCheck} /> producto agregado
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
