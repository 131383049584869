let camelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

let transformCategory = (array) => {
  let newarr = array.map((el) => el.nombrecategoria)
  return newarr.join(', ')
}

let url = 'https://res.cloudinary.com/ventafacil/image/'

const aux =
  'https://res.cloudinary.com/ventafacil/image/upload/v1670855858/VentaFacil/i1oopjhuo8gijyy3sr0u.png'

const removeLastSplitItem = (text, splitChar) => {
  if (!text) {
    return null
  } else {
    let splittedText = text?.split(splitChar)

    // splice remove a item of array, get last index and remove this
    splittedText.splice(splittedText.length, 1)

    return splittedText[splittedText.length - 1]
  }
}

let rezizeCloudinary = (image, width) => {
  if (!image) {
    return image
  } else {
    const img = removeLastSplitItem(image, 'upload/')

    let newUrl = url + 'upload/q_40/' + img
    return newUrl
  }
}

export { camelCase, transformCategory, rezizeCloudinary }
