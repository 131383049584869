export default function MinimalCategoryHeader({ category, length, previo,data }) {
    return (
        <div className='container pt-32 '>
            <div className='flex flex-col items-start gap-2 my-8'>
                <div className='w-full pb-6 mb-6 font-serif text-4xl font-bold tracking-tighter text-gray-700 border-b border-gray-300'>{category}</div>
                {length > 0 ?
                    (<div className='font-serif '>{length} "productos"</div>) :
                    ("no hay productos")
                }
            </div>
        </div>
    );
}